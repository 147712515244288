import { authState } from 'core/state';
import { client } from 'core/graphql/client';
import { removeToken } from 'services/AuthService';

export const logout = () => {
  client.clearStore();
  removeToken();
  authState({
    isLoggedIn: false,
    access_token: null,
    refresh_token: null,
    isTokenRefreshed: false,
    active_role: null,
  });
};
