import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Icon, IconNames } from 'atoms/Icon';
import fontService, { FontWeight } from 'services/FontService';
import { desktop } from 'services/ui';
import { useOutsideClick } from 'services/hooks';
import { Colors, UserRole } from 'context';
import { UserRoleSwitcher } from 'molecules/UserRoleSwitcher';
import i18n from '../../../i18n';

export interface IUserCard {
  name: string;
  role: string;
  avatarImage?: string | null;
  hasRoles: UserRole[];
  onChangeRole: (role: UserRole) => void;
}

export enum UserCardRole {
  Teacher,
  Student,
  Methodist,
}

export const getUserRoleTranslated = (role: UserCardRole): string =>
  ({
    [UserCardRole.Teacher]: i18n.t('menu.user.teacher'),
    [UserCardRole.Student]: i18n.t('menu.user.student'),
    [UserCardRole.Methodist]: i18n.t('menu.user.methodist'),
  }[role]);

export const UserCard = memo(({ name, role, hasRoles, avatarImage, onChangeRole }: IUserCard) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleClickOutside = () => {
    setIsOpened(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const onOpenSwitcher = useCallback(
    () => hasRoles.length > 1 && setIsOpened((prevState) => !prevState),
    [hasRoles],
  );

  const onChangeRoleClick = useCallback(
    (userRole: UserRole) => {
      onChangeRole(userRole);
      setIsOpened(false);
    },
    [onChangeRole],
  );

  return (
    <Wrapper>
      <ClickWrapper onClick={onOpenSwitcher} ref={ref}>
        <AvatarWrapper>
          {avatarImage ? (
            <AvatarImage src={avatarImage} alt={t('menu.user.avatarAlt')} />
          ) : (
            <Icon name={IconNames.Avatar} width="100%" height="100%" />
          )}
        </AvatarWrapper>
        <Info hasSeveralRoles={hasRoles.length > 1}>
          <UserInfo>
            {name}
            <InfoRole>
              {getUserRoleTranslated(UserCardRole[role as keyof typeof UserCardRole])}
            </InfoRole>
          </UserInfo>

          <OpenButton>
            <Icon
              name={isOpened ? IconNames.TriangleUp : IconNames.TriangleDown}
              width={12}
              height={12}
            />
          </OpenButton>
        </Info>
      </ClickWrapper>

      {isOpened && (
        <UserRoleSwitcherStyled
          name={name}
          roles={hasRoles}
          activeRole={role as UserRole}
          onChangeRole={onChangeRoleClick}
        />
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  position: relative;
`;

const ClickWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const AvatarWrapper = styled.div`
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;

  ${desktop} {
    margin-right: 12px;
  }
`;

const AvatarImage = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
  object-position: center;
`;

const Info = styled.div<{ hasSeveralRoles: boolean }>`
  cursor: ${({ hasSeveralRoles }) => hasSeveralRoles && 'pointer'};
  display: flex;
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.medium })}

  & button {
    display: ${({ hasSeveralRoles }) => !hasSeveralRoles && 'none'};
  }
`;

const UserInfo = styled.div`
  display: none;

  ${desktop} {
    display: block;
  }
`;

const InfoRole = styled.div`
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })}
  color: ${Colors.gray};
`;

const OpenButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  justify-self: flex-end;
  padding: 0;
  margin-left: 8px;

  ${desktop} {
    margin-left: 16px;
  }
`;

const UserRoleSwitcherStyled = styled(UserRoleSwitcher)`
  position: absolute;
  z-index: 111;
  top: 44px;
  right: 0;
`;
