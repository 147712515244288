import { IconNames } from 'atoms/Icon';
import { PATHS } from 'router/config';
import { ExternalPaths } from 'router/external';
import i18n from '../../i18n';

export enum Directions {
  right = 'right',
  left = 'left',
  up = 'up',
  down = 'down',
}

export enum Sizes {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum Days {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

export enum UserRole {
  STUDENT = 'Student',
  TEACHER = 'Teacher',
  METHODIST = 'Methodist',
}

export enum MenuItems {
  Courses = 'courses',
  Resume = 'resume',
}

export interface IMenuStructure {
  name: MenuItems;
  children: string;
  to: string;
  path: string;
  hasCount: boolean;
}

export const StudentMenu: IMenuStructure[] = [
  {
    name: MenuItems.Courses,
    children: `${i18n.t('menu.student.courses')}`,
    to: PATHS.courses,
    path: PATHS.courses,
    hasCount: true,
  },
  {
    name: MenuItems.Resume,
    children: `${i18n.t('menu.student.resume')}`,
    to: PATHS.resume,
    path: PATHS.resume,
    hasCount: false,
  },
];

export const TeacherMenu: IMenuStructure[] = [
  {
    name: MenuItems.Courses,
    children: `${i18n.t('menu.teacher.courses')}`,
    to: PATHS.courses,
    path: PATHS.courses,
    hasCount: true,
  },
];

export const MethodistMenu: IMenuStructure[] = [
  {
    name: MenuItems.Courses,
    children: `${i18n.t('menu.methodist.courses')}`,
    to: PATHS.courses,
    path: PATHS.courses,
    hasCount: true,
  },
];

export interface ISocialMediaData {
  name: string;
  link: string;
}

export const SOCIAL_MEDIA: ISocialMediaData[] = [
  { name: IconNames.FB, link: ExternalPaths.facebook },
  { name: IconNames.VK, link: ExternalPaths.vk },
  { name: IconNames.LinkedIn, link: ExternalPaths.linkedIn },
  { name: IconNames.Instagram, link: ExternalPaths.instagram },
  { name: IconNames.Telegram, link: ExternalPaths.telegram },
  { name: IconNames.Twitter, link: ExternalPaths.twitter },
  { name: IconNames.YT, link: ExternalPaths.youTube },
];

export enum CoursesFilter {
  all = 'all',
  inProgress = 'inProgress',
  finished = 'finished',
}
export interface ICoursesOptions {
  value: string;
  label: string;
  description?: string;
}

export const COURSES_OPTIONS: ICoursesOptions[] = [
  {
    value: CoursesFilter.all,
    label: `${i18n.t('coursesOption.all.label')}`,
    description: `${i18n.t('coursesOption.all.description')}`,
  },
  {
    value: CoursesFilter.inProgress,
    label: `${i18n.t('coursesOption.inProgress.label')}`,
    description: `${i18n.t('coursesOption.inProgress.description')}`,
  },
  {
    value: CoursesFilter.finished,
    label: `${i18n.t('coursesOption.finished.label')}`,
    description: `${i18n.t('coursesOption.finished.description')}`,
  },
];

export const COURSES_OPTIONS_METHODIST: ICoursesOptions[] = [
  {
    value: CoursesFilter.all,
    label: `${i18n.t('coursesOption.allMethodist.label')}`,
    description: `${i18n.t('coursesOption.allMethodist.description')}`,
  },
  {
    value: CoursesFilter.inProgress,
    label: `${i18n.t('coursesOption.inProgressMethodist.label')}`,
    description: `${i18n.t('coursesOption.inProgressMethodist.description')}`,
  },
  {
    value: CoursesFilter.finished,
    label: `${i18n.t('coursesOption.finishedMethodist.label')}`,
    description: `${i18n.t('coursesOption.finishedMethodist.description')}`,
  },
];

export enum Months {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export enum NetworkErrors {
  internalServerError = 500,
  serviceUnavailable = 503,
  pageIsNotAvailable = 404,
  otherError = 'error',
}

export enum AUTHORIZATION_STEPS {
  email = 'email',
  code = 'code',
}

export enum STATE_ACTIONS_TYPE {
  changeEmail = 'changeEmail',
  changeCode = 'changeCode',
  setEmailNotChanged = 'setEmailNotChanged',
}

export enum Languages {
  english = 'ENG',
  russian = 'RUS',
}

export enum LanguagesType {
  ENG = 'Eng',
  RUS = 'Rus',
}

export interface ISelectOptions {
  value: string;
  label: string;
}

export enum SocialMedia {
  github = 'GitHub',
  telegram = 'Telegram',
  linkedin = 'LinkedIn',
  viber = 'Viber',
  dribbble = 'Dribbble',
  behance = 'Behance',
  figma = 'Figma',
  googleDisk = 'Google Drive',
}

export const SocialMediaOptions: ISelectOptions[] = [
  {
    label: 'GitHub',
    value: SocialMedia.github,
  },
  {
    label: 'Telegram',
    value: SocialMedia.telegram,
  },
  {
    label: 'LinkedIn',
    value: SocialMedia.linkedin,
  },
  {
    label: 'Viber',
    value: SocialMedia.viber,
  },
  {
    label: 'Dribbble',
    value: SocialMedia.dribbble,
  },
  {
    label: 'Behance',
    value: SocialMedia.behance,
  },
  {
    label: 'Figma',
    value: SocialMedia.figma,
  },
  {
    label: 'Google Drive',
    value: SocialMedia.googleDisk,
  },
];

export interface ISocialMedia {
  id: string;
  name: SocialMedia | '';
  link: string;
}

export type SocialMediaType = {
  id: string;
  name: string;
};

export interface IResumeDate {
  year: string;
  month: string;
}

export interface IExperienceProject {
  id: string;
  name: string;
  problem: string;
  solution: string;
  technologies: string[];
}

export enum LanguageLevel {
  NATIVE = 'NATIVE',
  A1_ELEMENTARY = 'A1',
  A2_PRE_INTERMEDIATE = 'A2',
  B1_INTERMEDIATE = 'B1',
  B2_UPPER_INTERMEDIATE = 'B2',
  C1_ADVANCED = 'C1',
  C2_PROFICIENCY = 'C2',
}

export const LanguageLevelOptions: ISelectOptions[] = [
  {
    label: `${i18n.t('languageLevels.native')}`,
    value: LanguageLevel.NATIVE,
  },
  {
    label: `${i18n.t('languageLevels.elementary')}`,
    value: LanguageLevel.A1_ELEMENTARY,
  },
  {
    label: `${i18n.t('languageLevels.preIntermediate')}`,
    value: LanguageLevel.A2_PRE_INTERMEDIATE,
  },
  {
    label: `${i18n.t('languageLevels.intermediate')}`,
    value: LanguageLevel.B1_INTERMEDIATE,
  },
  {
    label: `${i18n.t('languageLevels.upperIntermediate')}`,
    value: LanguageLevel.B2_UPPER_INTERMEDIATE,
  },
  {
    label: `${i18n.t('languageLevels.advanced')}`,
    value: LanguageLevel.C1_ADVANCED,
  },
  {
    label: `${i18n.t('languageLevels.proficiency')}`,
    value: LanguageLevel.C2_PROFICIENCY,
  },
];

export interface ILanguages {
  id: string;
  language: string;
  level: LanguageLevel | '';
}

export enum ResumeFieldErrors {
  emptyField,
}

export enum TaskTypes {
  homework = 'homework',
  coursework = 'coursework',
  graduation_project = 'graduation_project',
}

export enum TaskPathsNames {
  homework = 'homework',
  coursework = 'coursework',
  graduation_project = 'graduationProject',
}

export enum TaskTabsNames {
  homework,
  coursework,
  graduation_project,
}

export enum GradeNames {
  DONE,
  CONTAINS_ERRORS,
  NOT_DONE,
}
