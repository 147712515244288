import React, { ChangeEvent, memo } from 'react';
import styled from 'styled-components';
import { Icon, IconNames } from 'atoms';
import { Colors } from 'context';
import { tablet } from 'services/ui';

import { EmailStep } from './EmailStep';
import { PassStep } from './PassStep';

export interface ILoginForm {
  values: ILoginFormValues;
  errors?: {
    [keys in keyof ILoginFormValues]?: string;
  };
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onEmailSendClick: () => void;
  disableEmailSend: boolean;
  isEmailSent: boolean;
  onPassChange: (value: string) => void;
  onPassSendClick: () => void;
  onBackButtonClick: () => void;
  disablePassSend: boolean;
  isOutOfTime: boolean;
  isOutOfAttempts: boolean;
  isServiceUnavailable: boolean;
  isOpenInfo: boolean;
  onHelpButtonClick: () => void;
}

interface ILoginFormValues {
  email: string;
  authenticationCode: string;
  time: string;
  waitingTimeForNextCode: number;
}

export const LoginFormComponent = memo(
  ({
    values,
    errors,
    onEmailChange,
    onEmailSendClick,
    disableEmailSend,
    isEmailSent,
    onPassChange,
    onPassSendClick,
    onBackButtonClick,
    disablePassSend,
    isOutOfTime = false,
    isOutOfAttempts = false,
    isServiceUnavailable = false,
    isOpenInfo,
    onHelpButtonClick,
  }: ILoginForm) => (
    <Wrapper>
      <LogoWrapper>
        <Logo name={IconNames.LogoVertical} color={Colors.white} />
      </LogoWrapper>

      <Content>
        {!isEmailSent ? (
          <EmailStep
            email={values.email}
            onEmailChange={onEmailChange}
            onEmailSendClick={onEmailSendClick}
            buttonDisabled={disableEmailSend}
            errorMessage={errors?.email}
            waitingTimeForNextCode={values.waitingTimeForNextCode}
          />
        ) : (
          <PassStep
            pass={values.authenticationCode}
            email={values.email}
            onPassChange={onPassChange}
            onPassSendClick={onPassSendClick}
            onBackButtonClick={onBackButtonClick}
            onHelpButtonClick={onHelpButtonClick}
            buttonDisabled={disablePassSend}
            timeLeft={values.time}
            errorMessage={errors?.authenticationCode}
            onEmailSendClick={onEmailSendClick}
            isOutOfTime={isOutOfTime}
            isOutOfAttempts={isOutOfAttempts}
            isServiceUnavailable={isServiceUnavailable}
            isOpenInfo={isOpenInfo}
            waitingTimeForNextCode={values.waitingTimeForNextCode}
          />
        )}
      </Content>
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  position: relative;
  width: 335px;
  border: 1px solid ${Colors.gray};
  border-radius: 24px;
  box-shadow: 0px 40px 80px #c0c6cb;
  background-color: ${Colors.white};

  ${tablet} {
    width: 432px;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: -1px;
  left: 20px;
  padding: 36px 10px 24px;
  border-radius: 0 0 8px 8px;
  background-color: ${Colors.black};

  ${tablet} {
    left: 55px;
    padding: 48px 12px 32px;
  }
`;

const Logo = styled(Icon)`
  height: 38px;
  width: 68px;

  ${tablet} {
    height: 48px;
    width: 78px;
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 128px 20px 30px;

  ${tablet} {
    padding: 160px 55px 34px;
  }
`;
