export const CLIENT_WIDTH = {
  MOBILE: 320,
  TABLET: 768,
  DESKTOP: 1220,
};

export const mobile = '@media (max-width: 767px)';
export const tablet = '@media (min-width: 768px)';
export const small_desktop = '@media (min-width: 1110px)';
export const desktop = '@media (min-width: 1220px)';
export const large_desktop = '@media (min-width: 1290px)';
export const max_desktop = '@media (min-width: 1500px)';

export const isHackerUTheme = () => process.env.REACT_APP_THEME === 'HACKERU';
export const isTMSTheme = () => (process.env.REACT_APP_THEME || 'TMS') === 'TMS';
