import * as React from 'react';
import { memo } from 'react';
import styled from 'styled-components';

import * as Icons from './Icons';

export enum IconNames {
  LogoHorisontal = 'LogoHorisontal',
  LogoHorisontalMobile = 'LogoHorisontalMobile',
  LogoVertical = 'LogoVertical',
  LogoFooter = 'LogoFooter',
  BurgerMenu = 'BurgerMenu',
  UnfoldMore = 'UnfoldMore',
  Create = 'Create',
  Check = 'Check',
  Loading = 'Loading',
  Support = 'Support',
  Timer = 'Timer',
  TimerEnd = 'TimerEnd',
  TimerInProcess = 'TimerInProcess',
  ArrowLeftSimple = 'ArrowLeftSimple',
  FB = 'FB',
  VK = 'VK',
  LinkedIn = 'LinkedIn',
  Instagram = 'Instagram',
  Telegram = 'Telegram',
  Twitter = 'Twitter',
  YT = 'YT',
  Avatar = 'Avatar',
  Warning = 'Warning',
  WhiteCheck = 'WhiteCheck',
  WhiteWarning = 'WhiteWarning',
  WhiteClose = 'WhiteClose',
  Folder = 'Folder',
  Error404 = 'Error404',
  Error500 = 'Error500',
  Error503 = 'Error503',
  Splash = 'Splash',
  Error = 'Error',
  SmallScreen = 'SmallScreen',
  Note = 'Note',
  TriangleDown = 'TriangleDown',
  TriangleUp = 'TriangleUp',
  Photo = 'Photo',
  GrayCloseSm = 'GrayCloseSm',
  CloseSm = 'CloseSm',
  Close = 'Close',
  GreenCheck = 'GreenCheck',
  RedError = 'RedError',
  WarningYellow = 'WarningYellow',
  LoadingGreen = 'LoadingGreen',
  Calendar = 'Calendar',
  Completed = 'Completed',
  Waiting = 'Waiting',
  Rejected = 'Rejected',
}

export interface IIconProps {
  name: IconNames;
  width?: number | string;
  height?: number | string;
  color?: string;
  className?: string;
}

const IconsMap: { [key: string]: any } = Icons;

export const Icon = memo(
  ({ name, width = 25, height = 25, color, className, ...props }: IIconProps) => {
    const IconComponent = IconsMap[name];

    return (
      <Wrapper $height={height} $width={width} className={className}>
        <IconComponent width="100%" height="100%" fill={color || 'none'} {...props} />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{ $height?: number | string; $width?: number | string }>`
  display: flex;
  height: ${({ $height }) => (typeof $height === 'number' ? `${$height}px` : $height)};
  width: ${({ $width }) => (typeof $width === 'number' ? `${$width}px` : $width)};
`;
