import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { Colors, UserRole } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { desktop } from 'services/ui';
import { Icon, IconNames } from 'atoms';
import { getUserRoleTranslated, UserCardRole } from 'molecules/Header/UserCard';

export interface IUserRoleBtn {
  id: UserRole;
  activeRole: UserRole;
  roles: UserRole[];
}

export interface IUserRoleSwitcher {
  name: string;
  roles: UserRole[];
  activeRole: UserRole;
  onChangeRole: (role: UserRole) => void;
  className?: string;
}

export const UserRoleButtonTitles = (t: TFunction) => ({
  [UserRole.STUDENT]: `${t('menu.user.student')}`,
  [UserRole.TEACHER]: `${t('menu.user.teacherSecond')}`,
  [UserRole.METHODIST]: `${t('menu.user.methodist')}`,
});

export const UserRoleSwitcher = memo(
  ({ name, roles, activeRole, onChangeRole, className }: IUserRoleSwitcher) => {
    const { t } = useTranslation();
    return (
      <Wrapper className={className}>
        <UserData>
          <UserName>{name}</UserName>
          <InfoRole>
            {getUserRoleTranslated(UserCardRole[activeRole as keyof typeof UserCardRole])}
          </InfoRole>
        </UserData>
        <Title>{t('menu.user.account')}</Title>
        <Switcher>
          {Object.values(UserRole).map(
            (btnRole) =>
              roles.includes(btnRole) && (
                <UserRoleButton
                  key={btnRole}
                  id={btnRole}
                  activeRole={activeRole}
                  roles={roles}
                  onClick={() => activeRole !== btnRole && onChangeRole(btnRole)}>
                  {UserRoleButtonTitles(t)[btnRole]}
                  <Icon name={IconNames.Check} />
                </UserRoleButton>
              ),
          )}
        </Switcher>
        <Info>
          {t('menu.user.severalAccounts')}
          <br /> {t('menu.user.switchAccount')}
        </Info>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  width: 246px;
  box-sizing: border-box;
  box-shadow: 0 8px 20px rgba(100, 100, 100, 0.4);
  background-color: ${Colors.white};
  border-radius: 4px;
  border-top: 5px solid ${Colors.main};
  padding: 20px 20px 24px;
`;

export const UserData = styled.div`
  margin-bottom: 24px;
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })};

  ${desktop} {
    display: none;
  }
`;

export const UserName = styled.div`
  ${fontService.getFontWeight(FontWeight.medium)};
`;

export const InfoRole = styled.div`
  color: ${Colors.gray};
`;

export const Title = styled.p`
  ${fontService.getFont({ size: 10, weight: FontWeight.medium, lineHeight: 120 })};
  color: ${Colors.gray_dark};
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

export const Switcher = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const Info = styled.p`
  ${fontService.getFont({ size: 12, weight: FontWeight.regular, lineHeight: 150 })};
  color: ${Colors.gray};
`;

export const UserRoleButton = styled.button<IUserRoleBtn>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  ${fontService.getFont({ size: 18, weight: FontWeight.bold, lineHeight: 145 })};
  color: ${({ id, activeRole }) => (id === activeRole ? Colors.black : Colors.gray_dark)};
  padding: 8px 0;

  & div {
    display: ${({ id, activeRole }) => id !== activeRole && 'none'};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.system_gray};
  }
`;
