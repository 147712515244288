import React, { useEffect } from 'react';

import { useGetAllAttachments, useGoToErrorPage } from 'core';
import { AttachmentType } from 'types';
import { JournalPreloader } from 'atoms';
import { LessonAttachmentsComponent } from './LessonAttachments';

interface ILessonAttachments {
  lessonId: string;
  type: AttachmentType;
}

export const LessonAttachments = ({ lessonId, type }: ILessonAttachments) => {
  const goToErrorPage = useGoToErrorPage();
  const { attachments, error, loading } = useGetAllAttachments(lessonId, type);

  useEffect(() => {
    if (error) {
      console.log({ attachmentsError: error });
      goToErrorPage();
    }
  }, [error, goToErrorPage]);

  return !loading && attachments ? (
    <LessonAttachmentsComponent attachments={attachments} type={type} />
  ) : (
    <JournalPreloader />
  );
};
