import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { Icon, IconNames } from 'atoms/Icon';
import { Button, ButtonSizes } from 'atoms';
import fontService, { FontWeight } from 'services/FontService';
import date from 'services/DateService';
import { Colors, SOCIAL_MEDIA } from 'context';
import { CLIENT_WIDTH, desktop, isHackerUTheme, small_desktop, tablet } from 'services/ui';
import { useWindowSize } from 'services/hooks';
import { ExternalPaths } from 'router/external';

export const Footer = memo(() => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  return (
    <FooterBackground>
      <FooterWrapper>
        <FooterInfo>
          <FooterInfoRequisites>
            <LogoWrapper>
              <Icon
                name={
                  width && width >= CLIENT_WIDTH.TABLET
                    ? IconNames.LogoFooter
                    : IconNames.LogoHorisontal
                }
                width="100%"
                height="100%"
              />
            </LogoWrapper>
          </FooterInfoRequisites>
          <FooterInfoContacts>
            <FooterInfoContactsContainer>
              <Contact href={ExternalPaths.mailto_info} target="_blank">
                {t('footer.infoEmail')}
              </Contact>
              <DefaultText>{t('footer.askQuestions')}</DefaultText>
            </FooterInfoContactsContainer>
            <SocialMediaIcons>
              {SOCIAL_MEDIA.map(({ link, name }, index) => (
                <SocialMediaIcon key={index} href={link} target="_blank">
                  <Icon name={name as IconNames} width="24px" height="24px" />
                </SocialMediaIcon>
              ))}
            </SocialMediaIcons>
          </FooterInfoContacts>
          <FooterInfoLinks>
            <FooterInfoTitle>{t('footer.about')}</FooterInfoTitle>
            <FooterInfoLinksList>
              <FooterInfoLinksLi>
                <DefaultLink href={ExternalPaths.contact_us} target="_blank">
                  {t('footer.contacts')}
                </DefaultLink>
              </FooterInfoLinksLi>
            </FooterInfoLinksList>
          </FooterInfoLinks>
          <FooterInfoBlog>
            <FooterInfoTitle>{t('footer.blog')}</FooterInfoTitle>
            <FooterInfoDescription>{t('footer.info')}</FooterInfoDescription>
            <DefaultLink href={ExternalPaths.blog} target="_blank">
              <Button variant="outline" size={ButtonSizes.sm}>
                {t('footer.readBlog')}
              </Button>
            </DefaultLink>
          </FooterInfoBlog>
        </FooterInfo>
        <Separator />
        <PrivacyPolicyContainer>
          <DefaultText>
            © {date.getFullYear()}, {t('footer.copyright')}
          </DefaultText>
        </PrivacyPolicyContainer>
      </FooterWrapper>
    </FooterBackground>
  );
});
const FooterBackground = styled.div`
  padding: 124px 0 32px;
  background: linear-gradient(180deg, rgba(244, 245, 247, 0) 0%, #f4f5f7 49.24%);
  width: 100%;
  margin-top: -124px;
`;

const FooterWrapper = styled.div`
  max-width: 1110px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: 0 40px;

  ${tablet} {
    padding: 0 20px;
  }

  ${desktop} {
    padding: 0;
  }
`;

const PrivacyPolicyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })}
  color: ${Colors.black};
`;

const DefaultText = styled.p`
  margin: 0;
  padding: 0;
`;

const DefaultLink = styled.a`
  margin: 0;
  padding: 0;
  color: ${Colors.black};
  &:hover {
    transition: 0.5s;
    color: ${Colors.gray_dark};
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.gray};
  opacity: 0.3;
  margin: 40px 0 16px 0;

  ${small_desktop} {
    margin: 48px 0 16px 0;
  }
`;

const FooterInfo = styled.div`
  display: grid;
  grid-template-areas:
    'logo'
    'contacts'
    'info'
    'blog';

  ${tablet} {
    grid-template-areas:
      'logo contacts info'
      '. . blog';
  }

  ${small_desktop} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }
`;

const FooterInfoRequisites = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  ${fontService.text({ size: 11, lineHeight: 145, weight: FontWeight.regular })}
  color: ${Colors.gray};
  grid-area: logo;
  margin: 0 0 32px 0;

  ${small_desktop} {
    margin: 0 70px 0 0;
    ${() =>
      isHackerUTheme() &&
      css`
        margin: 0 128px 0 0;
      `}
  }
`;

const LogoWrapper = styled.div`
  position: relative;
  right: 17px;
  width: 232px;
  height: 24px;
  ${tablet} {
    right: 0;
  }
  ${desktop} {
    right: 17px;
  }

  ${() =>
    isHackerUTheme() &&
    css`
      right: 0;
      width: 176px;
      height: 24px;
      ${tablet} {
        right: 0;
        width: 158px;
        height: 52px;
      }
      ${desktop} {
        right: 0;
      }
    `}
`;

const FooterInfoContacts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  ${fontService.text({ size: 11, lineHeight: 145, weight: FontWeight.regular })};
  color: ${Colors.gray};
  grid-area: info;
  margin: 0 0 24px 0;

  ${small_desktop} {
    margin: 0 68px 0 0;
  }
`;

const FooterInfoContactsContainer = styled.div``;

const Contact = styled(DefaultLink)`
  ${fontService.text({ size: 14, lineHeight: 150, weight: FontWeight.medium })};
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin: 15px 0 0 0;
`;

const SocialMediaIcon = styled.a`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0 4px 0 0;
  &:hover {
    & svg {
      & > :first-child {
        transition: 0.5s;
        fill: ${Colors.black};
      }
    }
  }
`;

const FooterInfoLinks = styled.div`
  margin: 0 0 32px 0;
  grid-area: contacts;

  ${small_desktop} {
    margin: 0 76px 0 0;
  }
`;

const FooterInfoTitle = styled(DefaultText)`
  ${fontService.text({ size: 14, lineHeight: 150, weight: FontWeight.bold })};
  color: ${Colors.black};
`;

const FooterInfoLinksList = styled.ul`
  margin: 8px 0 0 0;
  padding: 0;
  list-style: none;
`;

const FooterInfoLinksLi = styled.li`
  ${fontService.text({ size: 12, lineHeight: 200, weight: FontWeight.regular })};
  color: ${Colors.black};
  &:hover {
    transition: 0.5s;
    color: ${Colors.gray_dark};
  }
`;

const FooterInfoBlog = styled.div`
  width: 175px;
  grid-area: blog;
`;

const FooterInfoDescription = styled(DefaultText)`
  margin: 4px 0 7px 0;
  ${fontService.text({ size: 12, lineHeight: 150, weight: FontWeight.regular })};
  color: ${Colors.gray};
`;
