import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';
import { Icon, IconNames, IMenuItem, MenuItem, Modal } from 'atoms';
import { Colors, SOCIAL_MEDIA, UserRole } from 'context';
import fontService, { FontWeight } from 'services/FontService';
import { isHackerUTheme } from 'services/ui';
import { getUserRoleTranslated, UserCardRole } from 'molecules/Header/UserCard';
import {
  UserRoleButtonTitles,
  UserRoleButton,
  UserName,
  UserData,
  InfoRole,
  Title,
  Switcher,
  Info,
} from 'molecules/UserRoleSwitcher/UserRoleSwitcher';
import { ExternalPaths } from 'router/external';

interface IMenuModalProps {
  name: string;
  roles: UserRole[];
  activeRole: UserRole;
  onMenuClick: () => void;
  onChangeRole: (role: UserRole) => void;
  onExit: () => void;
  menuItems: IMenuItem[];
  isShowing: boolean;
}

export const MenuModal = memo(
  ({
    name,
    activeRole,
    roles,
    onMenuClick,
    onChangeRole,
    onExit,
    menuItems,
    isShowing,
  }: IMenuModalProps) => {
    const { t } = useTranslation();
    return (
      <Modal isShowing={isShowing} align="flex-start" hasMargin>
        <Wrapper>
          <TopWrapper>
            <LogoWrapper>
              <Icon name={IconNames.LogoHorisontal} width="100%" height="100%" />
            </LogoWrapper>
            <CloseBtn onClick={onMenuClick}>
              <Icon name={IconNames.Close} width={20} height={20} />
            </CloseBtn>
          </TopWrapper>
          <MenuWrapper>
            <MenuItemList>
              {menuItems.map((menuItem, index) => (
                <MenuItem
                  key={index}
                  to={menuItem.to}
                  subMenuCount={menuItem.subMenuCount}
                  isActive={menuItem.isActive}
                  disabled={menuItem.disabled}
                  onMenuItemClick={onMenuClick}>
                  {menuItem.children}
                </MenuItem>
              ))}
            </MenuItemList>
            <ContactsLink href={ExternalPaths.contact_us} target="_blank">
              {t('header.contacts')}
            </ContactsLink>
            <ExitButton onClick={onExit}>{t('header.exit')}</ExitButton>
          </MenuWrapper>
          <UserSwitchWrapper>
            <UserData>
              <StyledUserName>{name}</StyledUserName>
              <InfoRole>
                {getUserRoleTranslated(UserCardRole[activeRole as keyof typeof UserCardRole])}
              </InfoRole>
            </UserData>
            <Title>{t('menu.user.account')}</Title>
            <StyledSwitcher>
              {Object.values(UserRole).map(
                (btnRole) =>
                  roles.includes(btnRole) && (
                    <StyledUserRoleButton
                      key={btnRole}
                      id={btnRole}
                      activeRole={activeRole}
                      roles={roles}
                      onClick={() => activeRole !== btnRole && onChangeRole(btnRole)}>
                      {UserRoleButtonTitles(t)[btnRole]}
                      <Icon name={IconNames.Check} />
                    </StyledUserRoleButton>
                  ),
              )}
            </StyledSwitcher>
            <Info>
              {t('menu.user.severalAccounts')}
              <br /> {t('menu.user.switchAccount')}
            </Info>
          </UserSwitchWrapper>
          <InfoText>
            <Text>{t('header.text')}</Text>
            <SocialMediaIcons>
              {SOCIAL_MEDIA.map(({ link, name: mediaName }, index) => (
                <SocialMediaIcon key={index} href={link} target="_blank">
                  <Icon name={mediaName as IconNames} width="24px" height="24px" />
                </SocialMediaIcon>
              ))}
            </SocialMediaIcons>
          </InfoText>
        </Wrapper>
      </Modal>
    );
  },
);

const Wrapper = styled.div`
  padding: 20px 20px 40px;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const LogoWrapper = styled.div`
  width: 192px;
  height: 20px;
  ${() =>
    isHackerUTheme() &&
    css`
      width: 158px;
      height: 20px;
    `}
`;

const CloseBtn = styled.div``;

const MenuWrapper = styled.div`
  padding: 24px 20px 24px 20px;
  border-radius: 12px;
  border: 1px solid ${Colors.system_gray};
  margin-bottom: 20px;
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.medium })};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MenuItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContactsLink = styled.a`
  color: ${Colors.black};
`;

const ExitButton = styled.div``;

const UserSwitchWrapper = styled.div`
  padding: 24px 20px 24px 20px;
  border-radius: 12px;
  border: 1px solid ${Colors.system_gray};
  margin-bottom: 32px;
`;

const StyledUserName = styled(UserName)`
  ${fontService.getFont({ size: 16, lineHeight: 150, weight: FontWeight.medium })};
`;

const StyledSwitcher = styled(Switcher)`
  width: 78%;
  max-width: 230px;
`;

const StyledUserRoleButton = styled(UserRoleButton)`
  ${fontService.getFont({ size: 16, weight: FontWeight.medium, lineHeight: 150 })};
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.p`
  text-align: center;
  ${fontService.text({ size: 14, lineHeight: 142, weight: FontWeight.regular })}
  color: ${Colors.gray};
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

const SocialMediaIcon = styled.a`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0 4px 0 0;
  &:hover {
    & svg {
      & > :first-child {
        transition: 0.5s;
        fill: ${Colors.black};
      }
    }
  }
`;
