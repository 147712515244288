import { useCallback, useEffect } from 'react';
import { authState, LmsRoles, useRefreshTokenMutation } from 'core';
import { saveToken } from 'services/AuthService';

type RefreshTokenHookType = {
  updateTokens: (oldRefreshToken: string, role?: LmsRoles) => void;
  loading: boolean;
};

export const useRefreshToken = (): RefreshTokenHookType => {
  const [refreshTokenMutation, { data, loading }] = useRefreshTokenMutation({});

  useEffect(() => {
    if (data?.refreshToken?.errors) {
      authState({
        isLoggedIn: false,
        access_token: null,
        refresh_token: null,
        isTokenRefreshed: true,
        active_role: null,
      });
    }
  }, [data?.refreshToken?.errors]);

  useEffect(() => {
    if (data?.refreshToken?.token && data?.refreshToken?.refreshToken) {
      authState({
        isLoggedIn: true,
        access_token: data.refreshToken.token,
        refresh_token: data.refreshToken.refreshToken,
        isTokenRefreshed: true,
        active_role: data.refreshToken.payload.active_role,
      });
      saveToken(data.refreshToken.refreshToken);
    }
  }, [data]);

  const updateTokens = useCallback(
    async (oldRefreshToken: string, role?: LmsRoles) => {
      await refreshTokenMutation({
        variables: {
          refresh_token: oldRefreshToken,
          scope: process.env.SCOPE || 'LMS',
          activeRole: role,
        },
      });
    },
    [refreshTokenMutation],
  );

  return {
    updateTokens,
    loading,
  };
};
