import React, { memo, useCallback } from 'react';
import styled from 'styled-components';

import { Toast } from 'components/atoms';
import { IToastState } from 'core';
import { tablet } from 'services/ui';

export interface IToaster {
  toasts: IToastState;
}

export const Toaster = memo(({ toasts }: IToaster) => {
  const renderToasts = useCallback(
    () =>
      Object.keys(toasts).map((key) => {
        const ToastData = toasts[key];
        if (!ToastData) {
          return null;
        }

        return (
          <ToastContainer key={key}>
            <Toast toastKey={key} type={ToastData.type} text={ToastData.text} />
          </ToastContainer>
        );
      }),
    [toasts],
  );

  return <ToasterContainer>{renderToasts()}</ToasterContainer>;
});

const ToasterContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  pointer-events: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;

  ${tablet} {
    top: 60px;
    left: 50px;
    width: 100%;
  }
`;

const ToastContainer = styled.div`
  margin-bottom: 40px;
`;
