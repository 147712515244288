import { makeVar } from '@apollo/client';
import { getToken } from 'services/AuthService';

export interface IAuthState {
  isLoggedIn: boolean | null;
  access_token: string | null;
  refresh_token?: string | null;
  isTokenRefreshed: boolean;
  active_role?: string | null;
}

const refresh_token = getToken();

export const defaultAuthState: IAuthState = Object.freeze({
  isLoggedIn: !!refresh_token,
  access_token: null,
  refresh_token,
  isTokenRefreshed: false,
  active_role: null,
});

export const authState = makeVar(defaultAuthState);
