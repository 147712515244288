import { isTMSTheme } from 'services/ui';

import { ReactComponent as LogoVerticalTMS } from 'assets/icons/logo-vertical.svg';
import { ReactComponent as LogoVerticalHackerU } from 'assets/icons/logo-vertical_hackeru.svg';
import { ReactComponent as LogoHorisontalTMS } from 'assets/icons/logo-horisontal.svg';
import { ReactComponent as LogoHorisontalHackerU } from 'assets/icons/logo-horisontal_hackeru.svg';
import { ReactComponent as LogoHorisontalMobileTMS } from 'assets/icons/logo-horisontal_mobile.svg';
import { ReactComponent as LogoHorisontalMobileHackerU } from 'assets/icons/logo-horisontal_mobile_hackeru.svg';
import { ReactComponent as LogoFooterHackerU } from 'assets/icons/logo-footer_hackeru.svg';

import { ReactComponent as LoadingBlack } from 'assets/icons/loading.svg';
import { ReactComponent as LoadingWhite } from 'assets/icons/loading_white.svg';
import { ReactComponent as Error404TMS } from 'assets/icons/error404.svg';
import { ReactComponent as Error404HackerU } from 'assets/icons/error404_hackeru.svg';
import { ReactComponent as Error500TMS } from 'assets/icons/error500.svg';
import { ReactComponent as Error500HackerU } from 'assets/icons/error500_hackeru.svg';
import { ReactComponent as Error503TMS } from 'assets/icons/error503.svg';
import { ReactComponent as Error503HackerU } from 'assets/icons/error503_hackeru.svg';
import { ReactComponent as ErrorTMS } from 'assets/icons/error.svg';
import { ReactComponent as ErrorHackerU } from 'assets/icons/error_hackeru.svg';
import { ReactComponent as SplashTMS } from 'assets/icons/splash.svg';
import { ReactComponent as SplashHackerU } from 'assets/icons/splash_hackeru.svg';

const LogoVertical = isTMSTheme() ? LogoVerticalTMS : LogoVerticalHackerU;
const LogoHorisontal = isTMSTheme() ? LogoHorisontalTMS : LogoHorisontalHackerU;
const LogoHorisontalMobile = isTMSTheme() ? LogoHorisontalMobileTMS : LogoHorisontalMobileHackerU;
const LogoFooter = isTMSTheme() ? LogoHorisontalTMS : LogoFooterHackerU;
const Loading = isTMSTheme() ? LoadingBlack : LoadingWhite;
const Error404 = isTMSTheme() ? Error404TMS : Error404HackerU;
const Error500 = isTMSTheme() ? Error500TMS : Error500HackerU;
const Error503 = isTMSTheme() ? Error503TMS : Error503HackerU;
const Error = isTMSTheme() ? ErrorTMS : ErrorHackerU;
const Splash = isTMSTheme() ? SplashTMS : SplashHackerU;

export {
  LogoVertical,
  LogoHorisontal,
  LogoHorisontalMobile,
  LogoFooter,
  Loading,
  Error,
  Error500,
  Error503,
  Error404,
  Splash,
};

export { ReactComponent as FB } from 'assets/icons/fb.svg';
export { ReactComponent as Google } from 'assets/icons/google.svg';
export { ReactComponent as OK } from 'assets/icons/ok.svg';
export { ReactComponent as VK } from 'assets/icons/vk.svg';
export { ReactComponent as Yandex } from 'assets/icons/yandex.svg';
export { ReactComponent as LinkedIn } from 'assets/icons/lkd.svg';
export { ReactComponent as Instagram } from 'assets/icons/instagram.svg';
export { ReactComponent as Telegram } from 'assets/icons/tlg.svg';
export { ReactComponent as Twitter } from 'assets/icons/tw.svg';
export { ReactComponent as YT } from 'assets/icons/yt.svg';
export { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
export { ReactComponent as ArrowLeft } from 'assets/icons/arrow_left.svg';
export { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
export { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
export { ReactComponent as Check } from 'assets/icons/check.svg';
export { ReactComponent as Close } from 'assets/icons/close.svg';
export { ReactComponent as Fire } from 'assets/icons/fire.svg';
export { ReactComponent as Phone } from 'assets/icons/phone.svg';
export { ReactComponent as Rectangle } from 'assets/icons/rectangle.svg';
export { ReactComponent as Support } from 'assets/icons/support.svg';
export { ReactComponent as Avatar } from 'assets/icons/avatar.svg';
export { ReactComponent as Timer } from 'assets/icons/timer.svg';
export { ReactComponent as TimerEnd } from 'assets/icons/timerend.svg';
export { ReactComponent as TimerInProcess } from 'assets/icons/timerinprocess.svg';
export { ReactComponent as ArrowLeftSimple } from 'assets/icons/arrow_left_simple.svg';
export { ReactComponent as Warning } from 'assets/icons/warning.svg';
export { ReactComponent as WhiteCheck } from 'assets/icons/white_check.svg';
export { ReactComponent as WhiteWarning } from 'assets/icons/white_warning.svg';
export { ReactComponent as WhiteClose } from 'assets/icons/white_close.svg';
export { ReactComponent as Folder } from 'assets/icons/folder.svg';
export { ReactComponent as SmallScreen } from 'assets/icons/smallScreen.svg';
export { ReactComponent as Note } from 'assets/icons/note.svg';
export { ReactComponent as TriangleDown } from 'assets/icons/triangle_down.svg';
export { ReactComponent as TriangleUp } from 'assets/icons/triangle_up.svg';
export { ReactComponent as Photo } from 'assets/icons/photo_default.svg';
export { ReactComponent as GrayCloseSm } from 'assets/icons/close_gray_sm.svg';
export { ReactComponent as CloseSm } from 'assets/icons/close_sm.svg';
export { ReactComponent as GreenCheck } from 'assets/icons/check_green.svg';
export { ReactComponent as RedError } from 'assets/icons/error_red.svg';
export { ReactComponent as WarningYellow } from 'assets/icons/warning_yellow.svg';
export { ReactComponent as LoadingGreen } from 'assets/icons/loading_green.svg';
export { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
export { ReactComponent as Completed } from 'assets/icons/status-completed.svg';
export { ReactComponent as Waiting } from 'assets/icons/status-waiting.svg';
export { ReactComponent as Rejected } from 'assets/icons/status-rejected.svg';
export { ReactComponent as BurgerMenu } from 'assets/icons/burger.svg';
export { ReactComponent as UnfoldMore } from 'assets/icons/unfold-more.svg';
export { ReactComponent as Create } from 'assets/icons/create.svg';
