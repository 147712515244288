import React, { memo, useCallback, useState } from 'react';

import styled from 'styled-components';
import { Colors, UserRole } from 'context';
import { Icon, IconNames, IMenuItem } from 'atoms';

import { MenuModal } from './MenuModal';

interface IMobileMenuProps {
  name: string;
  activeRole: string;
  avatarImage?: string | null;
  hasRoles: UserRole[];
  onChangeRole: (role: UserRole) => void;
  onExit: () => void;
  menuItems: IMenuItem[];
}

export const MobileHeader = memo(
  ({
    name,
    activeRole,
    avatarImage,
    hasRoles,
    onChangeRole,
    onExit,
    menuItems,
  }: IMobileMenuProps) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const onMenuClick = useCallback(() => setIsOpened((prevState) => !prevState), []);
    const onChangeRoleClick = useCallback(
      (userRole: UserRole) => {
        onChangeRole(userRole);
        setIsOpened(false);
      },
      [onChangeRole],
    );
    return (
      <Wrapper>
        <Content>
          <LogoWrapper>
            <Icon name={IconNames.LogoHorisontalMobile} width="100%" height="100%" />
          </LogoWrapper>
          <MenuWrapper>
            <UserAvatar>
              {avatarImage ? (
                <AvatarImage src={avatarImage} alt="" />
              ) : (
                <Icon name={IconNames.Avatar} width="100%" height="100%" />
              )}
            </UserAvatar>

            <MenuButton onClick={onMenuClick}>
              <Icon name={IconNames.BurgerMenu} width="100%" height="100%" />
            </MenuButton>
          </MenuWrapper>
        </Content>
        <Separator />
        <MenuModal
          name={name}
          roles={hasRoles}
          activeRole={activeRole as UserRole}
          onMenuClick={onMenuClick}
          onChangeRole={onChangeRoleClick}
          onExit={onExit}
          menuItems={menuItems}
          isShowing={isOpened}
        />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.div`
  width: 118px;
  height: 28px;
`;

const MenuWrapper = styled.div`
  display: flex;
`;

const UserAvatar = styled.div`
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 16px;
`;

const AvatarImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  object-position: center;
`;

const MenuButton = styled.div`
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: ${Colors.bg_gray};
  padding: 10px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.system_gray};
  opacity: 0.5;
  margin: 16px 0 0 0;
`;
