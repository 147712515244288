import React, { memo } from 'react';

import { useGetStudentMarks } from 'core';
import { JournalPreloader } from 'atoms';
import { TaskResultsComponent } from './TaskResults';

interface ITaskResults {
  lessonsId: string;
}

export const TaskResults = memo(({ lessonsId }: ITaskResults) => {
  const { marks, loading } = useGetStudentMarks(lessonsId);

  return !loading && marks?.length ? (
    <TaskResultsComponent studentMarks={marks} />
  ) : (
    <JournalPreloader />
  );
});
