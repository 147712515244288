import React, { memo, useMemo } from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Colors, TaskPathsNames, TaskTabsNames, TaskTypes } from 'context';
import { Button, ButtonSizes } from 'atoms';
import { CourseworkCheck, HomeworkCheck } from 'organisms';
import { PATHS } from 'router/config';
import { LessonType } from 'types';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, tablet } from 'services/ui';
import { getTaskTypesTranslated } from 'services/TranslationService';

export interface ITasksTabs {
  currentLesson: LessonType;
  pathToTasks: string;
}

export const TasksTabs = memo(({ currentLesson, pathToTasks }: ITasksTabs) => {
  const { t } = useTranslation();
  const taskTypes = useMemo(() => currentLesson.taskTypes || [], [currentLesson.taskTypes]);
  const lessonDateParsed = useMemo(
    () => DateTime.fromISO(currentLesson.date).toJSDate(),
    [currentLesson.date],
  );

  const defaultTaskToShow = useMemo(() => {
    switch (true) {
      case taskTypes.includes(TaskTypes.homework):
        return PATHS.homework;
      case taskTypes.includes(TaskTypes.coursework):
        return PATHS.coursework;
      case taskTypes.includes(TaskTypes.graduation_project):
        return PATHS.graduationProject;
      default:
        return PATHS.main;
    }
  }, [taskTypes]);

  const { pathname } = useLocation();
  const currentLocation = pathname.substring(pathname.lastIndexOf('/') + 1);

  return (
    <Wrapper>
      <TasksTitle>{t('tasks.taskType')}</TasksTitle>
      <TabsWrapper>
        {taskTypes.map((taskType, index) => (
          <Link
            key={index}
            to={`${pathToTasks}/${TaskPathsNames[taskType as keyof typeof TaskPathsNames]}`}>
            <StyledButton
              isActive={currentLocation === TaskPathsNames[taskType as keyof typeof TaskPathsNames]}
              size={ButtonSizes.sm}
              sidePaddings={12}>
              {getTaskTypesTranslated(TaskTabsNames[taskType as keyof typeof TaskTabsNames])}
            </StyledButton>
          </Link>
        ))}
      </TabsWrapper>
      <TaskContentWrapper>
        <Routes>
          <Route path="/" element={<Navigate to={defaultTaskToShow} replace />} />
          <Route
            path={PATHS.homework}
            element={
              <HomeworkCheck
                lessonId={currentLesson.id}
                taskType={TaskTypes.homework}
                lessonDate={lessonDateParsed}
              />
            }
          />
          <Route
            path={PATHS.coursework}
            element={
              <CourseworkCheck
                key={TaskTypes.coursework}
                lessonId={currentLesson.id}
                lessonDate={lessonDateParsed}
                taskType={TaskTypes.coursework}
              />
            }
          />
          <Route
            path={PATHS.graduationProject}
            element={
              <CourseworkCheck
                key={TaskTypes.graduation_project}
                lessonId={currentLesson.id}
                lessonDate={lessonDateParsed}
                taskType={TaskTypes.graduation_project}
              />
            }
          />
        </Routes>
      </TaskContentWrapper>
    </Wrapper>
  );
});

const Wrapper = styled.div``;

const TasksTitle = styled.h3`
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}
  margin-bottom: 12px;

  ${tablet} {
    ${fontService.h3()};
    margin-bottom: 24px;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 32px;

  ${tablet} {
    flex-wrap: nowrap;
    margin-bottom: 40px;
  }

  ${desktop} {
    margin-bottom: 60px;
  }
`;

const StyledButton = styled(Button)<{ isActive: boolean }>`
  background-color: ${({ isActive }) => !isActive && Colors.additional_gray};
  color: ${({ isActive }) => !isActive && Colors.black};
`;

const TaskContentWrapper = styled.div``;
