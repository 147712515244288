import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { Icon, IconNames } from 'atoms/Icon';
import fontService, { FontWeight } from 'services/FontService';
import { desktop, isHackerUTheme, tablet } from 'services/ui';

import { authState } from 'core';
import { useCourses, useFilter, useGoToErrorPage } from 'core/hooks';

import {
  COURSES_OPTIONS,
  COURSES_OPTIONS_METHODIST,
  Colors,
  Directions,
  Sizes,
  CoursesFilter,
  UserRole,
} from 'context';

import {
  DropDown,
  Button,
  ButtonSizes,
  Arrow,
  CoursesPreloader,
  FilterPreloader,
} from 'components/atoms';
import { CourseCard } from 'components/molecules';

export const Courses = memo(() => {
  const { t } = useTranslation();
  const { active_role } = useReactiveVar(authState);
  const {
    data,
    error,
    pageInfo,
    loading,
    operations: { fetchMoreData },
  } = useCourses();
  const { isOpen, selectedOption, toggling, onOptionClicked } = useFilter(active_role as UserRole);
  const goToErrorPage = useGoToErrorPage();

  const loadMore = useCallback(() => {
    const vars = {
      finished: selectedOption.value === CoursesFilter.finished,
      active: selectedOption.value === CoursesFilter.inProgress,
      after: pageInfo?.endCursor,
    };
    fetchMoreData(vars);
  }, [fetchMoreData, pageInfo?.endCursor, selectedOption.value]);

  useEffect(() => {
    fetchMoreData({
      finished: selectedOption.value === CoursesFilter.finished,
      active: selectedOption.value === CoursesFilter.inProgress,
    });
  }, [fetchMoreData, selectedOption.value, active_role]);

  useEffect(() => {
    if (error) {
      console.log({ coursesError: error });
      goToErrorPage();
    }
  }, [error, goToErrorPage]);

  return (
    <CoursesPageWrapper>
      <DropDownWrapper>
        {loading ? (
          <FilterPreloader />
        ) : (
          <DropDown
            options={
              active_role === UserRole.METHODIST ? COURSES_OPTIONS_METHODIST : COURSES_OPTIONS
            }
            toggling={toggling}
            onOptionClicked={onOptionClicked}
            selectedOption={selectedOption}
            isOpen={isOpen}
          />
        )}
      </DropDownWrapper>

      {!loading &&
        Boolean(data.length) &&
        data.map((course) => (
          <CourseCardWrapper key={`${course.id}`}>
            <LinkStyled to={`/courses/${course.id}`}>
              <CourseCard
                key={`${course.id}`}
                id={course.id}
                courseId={course.courseId}
                courseName={course.courseName}
                groupName={course.groupName}
                studentsCount={course.studentsCount}
                courseImageURL={course.courseImageURL}
                activeLessonNumber={course.activeLessonNumber}
                lessonsCount={course.lessonsCount}
                nextLesson={course.nextLesson}
                educationForm={course.educationForm}
                statusFinished={course.statusFinished}
              />
            </LinkStyled>
          </CourseCardWrapper>
        ))}
      {!loading && !data.length && (
        <NoCoursesWrapper>
          <IconStyled name={IconNames.Folder} />
          <TextLarge>{t('courses.noCourses')}</TextLarge>
          <TextSmall>{t('courses.noCoursesText')}</TextSmall>
        </NoCoursesWrapper>
      )}

      {loading && <CoursesPreloader />}

      {!loading && pageInfo?.hasNextPage && (
        <ButtonWrapper>
          <Button variant="outline" size={ButtonSizes.md} onClick={() => loadMore()}>
            {t('courses.loadMore')}
            <ArrowWrapper>
              <StyledArrow direction={Directions.down} size={Sizes.md} />
            </ArrowWrapper>
          </Button>
        </ButtonWrapper>
      )}
    </CoursesPageWrapper>
  );
});

const DropDownWrapper = styled.div`
  margin: 32px 0 20px 0;

  ${tablet} {
    margin: 40px 0 20px 0;
  }

  ${desktop} {
    margin: 54px 0 32px 0;
  }
`;

const CourseCardWrapper = styled.div`
  margin-bottom: 20px;

  ${desktop} {
    margin-bottom: 32px;
  }
`;

const LinkStyled = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: ${Colors.black};
`;

const CoursesPageWrapper = styled.div`
  width: 100%;
  margin-bottom: 80px;
`;

const NoCoursesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 84px;
`;
const IconStyled = styled((props) => <Icon {...props} />)`
  margin-bottom: 32px;
  width: 100px;
  height: 83px;
`;

const TextLarge = styled.p`
  ${fontService.text({ size: 16, lineHeight: 150, weight: FontWeight.bold })};
  color: ${Colors.gray};
  margin-bottom: 8px;

  ${tablet} {
    ${fontService.text({ size: 24, lineHeight: 133, weight: FontWeight.bold })};
  }
`;
const TextSmall = styled.p`
  text-align: center;
  ${fontService.text({ size: 12, lineHeight: 142, weight: FontWeight.regular })};
  color: ${Colors.gray};

  ${tablet} {
    ${fontService.text({ size: 14, lineHeight: 150, weight: FontWeight.regular })};
  }
`;

const StyledArrow = styled(Arrow)``;

const ButtonWrapper = styled.div`
  width: 224px;
  margin: 0 auto;

  ${() =>
    isHackerUTheme() &&
    css`
      &:hover {
        ${StyledArrow} {
          transition: 0.5s;
          border-color: ${Colors.white};
        }
      }
    `}
`;

const ArrowWrapper = styled.div`
  margin: 0 0 5px 15px;
`;
