import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Float` scalar type represents signed double-precision fractional.
   * In query it is displayed as a string.
   */
  CustomFloat: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   *
   *     Errors messages and codes mapped to
   *     fields or non fields errors.
   *     Example:
   *     {
   *         field_name: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ],
   *         other_field: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ],
   *         nonFieldErrors: [
   *             {
   *                 "message": "error message",
   *                 "code": "error_code"
   *             }
   *         ]
   *     }
   *
   */
  ExpectedErrorType: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

/** An enumeration. */
export enum AccountsPayableCurrency {
  /** BYN */
  Byn = 'BYN',
  /** EUR */
  Eur = 'EUR',
  /** RUB */
  Rub = 'RUB',
  /** USD */
  Usd = 'USD'
}

export type AccountsPayableInput = {
  accountsPayable?: InputMaybe<Scalars['Float']>;
  date: Scalars['Date'];
};

export type AccountsPayableType = {
  __typename?: 'AccountsPayableType';
  agreement?: Maybe<AgreementType>;
  amountByn?: Maybe<Scalars['CustomFloat']>;
  amountForeign?: Maybe<Scalars['CustomFloat']>;
  currency: AccountsPayableCurrency;
  date: Scalars['Date'];
  id: Scalars['ID'];
  repaid: Scalars['Boolean'];
  startedAgreement: AgreementType;
};

export type AddNewRoleTeacher = {
  __typename?: 'AddNewRoleTeacher';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<TeacherType>;
};

export type AddRoleStudentAndAgreement = {
  __typename?: 'AddRoleStudentAndAgreement';
  agreement?: Maybe<AgreementType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddTeacherApplication = {
  __typename?: 'AddTeacherApplication';
  application?: Maybe<ApplicationType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddTeacherExist = {
  __typename?: 'AddTeacherExist';
  application?: Maybe<ApplicationType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum AgreementCurrency {
  /** BYN */
  Byn = 'BYN',
  /** EUR */
  Eur = 'EUR',
  /** RUB */
  Rub = 'RUB',
  /** USD */
  Usd = 'USD'
}

export type AgreementInput = {
  agreementTitle: Scalars['String'];
  comment?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['ID']>;
  currency: Scalars['String'];
  date?: InputMaybe<Scalars['Date']>;
  group_id: Scalars['ID'];
  partialAttendance?: InputMaybe<Scalars['Boolean']>;
  paymentByCompany?: InputMaybe<Scalars['Boolean']>;
  promocode?: InputMaybe<Scalars['String']>;
  resident?: InputMaybe<Scalars['Boolean']>;
  startAgreementBudget?: InputMaybe<Scalars['Float']>;
  terminated?: InputMaybe<Scalars['Boolean']>;
  terminatedDate?: InputMaybe<Scalars['Date']>;
  vat?: InputMaybe<Scalars['Boolean']>;
};

export type AgreementLessonsInput = {
  lastLessonId: Scalars['Int'];
  startLessonId: Scalars['Int'];
};

export type AgreementType = {
  __typename?: 'AgreementType';
  accountsPayable: Array<AccountsPayableType>;
  agreementTitle: Scalars['String'];
  attendancesInfo?: Maybe<Scalars['Float']>;
  budgetAmount?: Maybe<Scalars['CustomFloat']>;
  chargeback?: Maybe<ChargebackType>;
  comment?: Maybe<Scalars['String']>;
  company?: Maybe<CompanyType>;
  contributions: ContributionTypeConnection;
  country?: Maybe<CountryType>;
  courseWorksAverage?: Maybe<Scalars['Float']>;
  currency: AgreementCurrency;
  date: Scalars['Date'];
  firstLesson?: Maybe<GroupLessonType>;
  graduationProjectMark?: Maybe<Scalars['Float']>;
  group: MethodistGroupType;
  hwSummary?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  lastLesson?: Maybe<GroupLessonType>;
  paidAmount?: Maybe<Scalars['CustomFloat']>;
  partialAttendance: Scalars['Boolean'];
  paymentByCompany: Scalars['Boolean'];
  payments?: Maybe<Array<Maybe<PaymentType>>>;
  promocode?: Maybe<Scalars['String']>;
  resident: Scalars['Boolean'];
  startAccountsPayable?: Maybe<AccountsPayableType>;
  student: StudentType;
  terminated: Scalars['Boolean'];
  terminatedDate?: Maybe<Scalars['Date']>;
  vat: Scalars['Boolean'];
};


export type AgreementTypeContributionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderByDateFilter?: InputMaybe<Scalars['Boolean']>;
};

export type AllStoriesConnection = {
  __typename?: 'AllStoriesConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllStoriesEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllStories` and its cursor. */
export type AllStoriesEdge = {
  __typename?: 'AllStoriesEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AllStoriesType>;
};

export type AllStoriesType = ArticlesType | NativeInfoType | SuccessStoriesType;

export type ApplicationConnection = {
  __typename?: 'ApplicationConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApplicationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ApplicationCurrency {
  /** BYN */
  Byn = 'BYN',
  /** EUR */
  Eur = 'EUR',
  /** RUB */
  Rub = 'RUB',
  /** USD */
  Usd = 'USD'
}

/** An enumeration. */
export enum ApplicationDeleteAvailable {
  DeleteAvailable = 'delete_available',
  DeleteNotAvailable = 'delete_not_available',
  DeleteWithTeacher = 'delete_with_teacher'
}

/** A Relay edge containing a `Application` and its cursor. */
export type ApplicationEdge = {
  __typename?: 'ApplicationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ApplicationType>;
};

export type ApplicationFilterTypeConnection = {
  __typename?: 'ApplicationFilterTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApplicationFilterTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ApplicationFilterType` and its cursor. */
export type ApplicationFilterTypeEdge = {
  __typename?: 'ApplicationFilterTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ApplicationType>;
};

export type ApplicationInputType = {
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['ID']>;
  currency: Currencies;
  date: Scalars['Date'];
  group: Scalars['ID'];
  lessonsCount: Scalars['Int'];
  riaPrice?: InputMaybe<Scalars['Float']>;
  servicePrice: Scalars['Float'];
  teacherType: EmploymentTypes;
  title: Scalars['String'];
};

export type ApplicationType = Node & {
  __typename?: 'ApplicationType';
  appointedLessonsCount: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<CountryType>;
  currency: ApplicationCurrency;
  date: Scalars['Date'];
  deleteAvailable?: Maybe<ApplicationDeleteAvailable>;
  employmentType?: Maybe<EmploymentType>;
  group: MethodistGroupType;
  id: Scalars['ID'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  isTerminated: Scalars['Boolean'];
  lastLessonDate?: Maybe<Scalars['Date']>;
  lessons: GroupLessonTypeConnection;
  lessonsCount: Scalars['Int'];
  riaPrice?: Maybe<Scalars['Float']>;
  servicePrice?: Maybe<Scalars['Float']>;
  teacher: TeacherFilterType;
  title: Scalars['String'];
};


export type ApplicationTypeLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type ArticlesConnection = {
  __typename?: 'ArticlesConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ArticlesEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Articles` and its cursor. */
export type ArticlesEdge = {
  __typename?: 'ArticlesEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ArticlesType>;
};

export type ArticlesType = {
  __typename?: 'ArticlesType';
  adviceText?: Maybe<Scalars['String']>;
  adviceTitle?: Maybe<Scalars['String']>;
  articleImage?: Maybe<Scalars['String']>;
  articleShortText?: Maybe<Scalars['String']>;
  author: AuthorsType;
  banner?: Maybe<CourseBanner>;
  date: Scalars['Date'];
  id: Scalars['ID'];
  native?: Maybe<NativeInfoType>;
  nativeTitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoSlug?: Maybe<Scalars['String']>;
  seoTitle: Scalars['String'];
  storyText?: Maybe<Scalars['String']>;
  storyTime?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AttendanceType = Node & {
  __typename?: 'AttendanceType';
  attendance?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lesson: GroupLessonType;
  student: StudentType;
};

export type AttendanceTypeConnection = {
  __typename?: 'AttendanceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AttendanceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AttendanceType` and its cursor. */
export type AttendanceTypeEdge = {
  __typename?: 'AttendanceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AttendanceType>;
};

export type AuthorsType = {
  __typename?: 'AuthorsType';
  articlesSet: Array<ArticlesType>;
  authorImage: Scalars['String'];
  authorName: Scalars['String'];
  authorPosition: Scalars['String'];
  id: Scalars['ID'];
  successstoriesSet: Array<SuccessStoriesType>;
};

export type BaseTeacherInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type BlackFridayLeadInput = {
  course_id?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  formName: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  promocode?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  roistatId: Scalars['String'];
  tittle: Scalars['String'];
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

export type BlogAllArticlesType = {
  __typename?: 'BlogAllArticlesType';
  id: Scalars['ID'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
};

export type BlogGraduateStoryType = {
  __typename?: 'BlogGraduateStoryType';
  description: Scalars['String'];
  id: Scalars['ID'];
  image: Scalars['String'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};

export type BlogNativeType = {
  __typename?: 'BlogNativeType';
  description: Scalars['String'];
  id: Scalars['ID'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};

export type CeoInfoType = {
  __typename?: 'CEOInfoType';
  course?: Maybe<CourseFilterType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  image: Scalars['String'];
  keywords: Scalars['String'];
  title: Scalars['String'];
};

export type CancelVideoUpload = {
  __typename?: 'CancelVideoUpload';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Category` and its cursor. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CategoryType>;
};

export type CategoryType = {
  __typename?: 'CategoryType';
  categoryColumn?: Maybe<Scalars['String']>;
  courses: CourseFilterTypeConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type CategoryTypeCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category_Name?: InputMaybe<Scalars['String']>;
  educationForm?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderByPublishDateFilter?: InputMaybe<Scalars['Boolean']>;
  readyToPublish?: InputMaybe<Scalars['Boolean']>;
  startDateFilter?: InputMaybe<Scalars['Boolean']>;
};

export type ChangeLessonDate = {
  __typename?: 'ChangeLessonDate';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum ChargebackCurrency {
  /** BYN */
  Byn = 'BYN',
  /** EUR */
  Eur = 'EUR',
  /** RUB */
  Rub = 'RUB',
  /** USD */
  Usd = 'USD'
}

export type ChargebackInput = {
  currency: Scalars['String'];
  date: Scalars['Date'];
  sum: Scalars['Float'];
};

export type ChargebackType = {
  __typename?: 'ChargebackType';
  agreement: AgreementType;
  amountByn?: Maybe<Scalars['CustomFloat']>;
  amountForeign?: Maybe<Scalars['CustomFloat']>;
  currency: ChargebackCurrency;
  date: Scalars['Date'];
  id: Scalars['ID'];
};

export type CheckStudentsDebtMutation = {
  __typename?: 'CheckStudentsDebtMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Company` and its cursor. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CompanyType>;
};

export type CompanyImageType = {
  __typename?: 'CompanyImageType';
  id: Scalars['ID'];
  image: Scalars['String'];
  studentAndCompanies: StudentAndCompaniesType;
};

export type CompanyInput = {
  comment?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  registrationNumber: Scalars['String'];
  responsiblePerson: Scalars['String'];
};

export type CompanyType = Node & {
  __typename?: 'CompanyType';
  agreements: Array<AgreementType>;
  comment?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  registrationNumber: Scalars['String'];
  responsiblePerson: Scalars['String'];
};

export type ContactEmailType = {
  __typename?: 'ContactEmailType';
  contactsPage: ContactsPageType;
  contactspage?: Maybe<ContactsPageType>;
  email: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ContactPhoneType = {
  __typename?: 'ContactPhoneType';
  contactsPage: ContactsPageType;
  id: Scalars['ID'];
  phone: Scalars['String'];
  title: Scalars['String'];
};

export type ContactsPageType = {
  __typename?: 'ContactsPageType';
  address: Scalars['String'];
  emails: Array<ContactEmailType>;
  id: Scalars['ID'];
  mainEmail?: Maybe<ContactEmailType>;
  paymentsLogo: Array<PaymentLogoType>;
  phones: Array<ContactPhoneType>;
  registrationData: Scalars['String'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
};

export type ContributionConnection = {
  __typename?: 'ContributionConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContributionEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Contribution` and its cursor. */
export type ContributionEdge = {
  __typename?: 'ContributionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ContributionType>;
};

export type ContributionInput = {
  date: Scalars['Date'];
  sum: Scalars['Float'];
};

export type ContributionType = Node & {
  __typename?: 'ContributionType';
  agreement: AgreementType;
  agreementTitle?: Maybe<Scalars['String']>;
  amountByn?: Maybe<Scalars['CustomFloat']>;
  amountForeign?: Maybe<Scalars['CustomFloat']>;
  complete: Scalars['Boolean'];
  date: Scalars['Date'];
  delay?: Maybe<Scalars['Int']>;
  group?: Maybe<GroupsType>;
  id: Scalars['ID'];
  person?: Maybe<Person>;
  remainder?: Maybe<Scalars['CustomFloat']>;
};

export type ContributionTypeConnection = {
  __typename?: 'ContributionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContributionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContributionType` and its cursor. */
export type ContributionTypeEdge = {
  __typename?: 'ContributionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ContributionType>;
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CountryEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Country` and its cursor. */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CountryType>;
};

export type CountryType = {
  __typename?: 'CountryType';
  agreements: Array<AgreementType>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type CourseBanner = {
  __typename?: 'CourseBanner';
  articlesSet: Array<ArticlesType>;
  course?: Maybe<CourseFilterType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  native: Array<NativeInfoType>;
  successstoriesSet: Array<SuccessStoriesType>;
  title: Scalars['String'];
};

export type CourseCatalogPageType = {
  __typename?: 'CourseCatalogPageType';
  description: Scalars['String'];
  id: Scalars['ID'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};

export type CourseContributionPriceType = {
  __typename?: 'CourseContributionPriceType';
  amount: Scalars['Float'];
  contribution: CourseContributionType;
  currency?: Maybe<CurrencyType>;
  currencyTicker: Scalars['String'];
  id: Scalars['ID'];
};

export type CourseContributionType = {
  __typename?: 'CourseContributionType';
  course: CourseFilterType;
  description: Scalars['String'];
  id: Scalars['ID'];
  prices: Array<CourseContributionPriceType>;
};

export type CourseFilterType = Node & {
  __typename?: 'CourseFilterType';
  category?: Maybe<CategoryType>;
  ceoInfo?: Maybe<CeoInfoType>;
  contributions: Array<CourseContributionType>;
  courseBanner?: Maybe<CourseBanner>;
  courseDiscount?: Maybe<Scalars['Int']>;
  courseIcon?: Maybe<Scalars['String']>;
  courseRequirementsInfo?: Maybe<CourseRequirementsIntoType>;
  currency?: Maybe<Scalars['String']>;
  curriculumVitae?: Maybe<StudentCvType>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  duration: Scalars['String'];
  educationForm?: Maybe<Scalars['String']>;
  educationprogram?: Maybe<EducationProgramType>;
  futureKnowledge?: Maybe<FutureKnowledgeType>;
  futureProfession?: Maybe<FutureProfessionType>;
  graduateFeedbacks: Array<GraduateFeedbackType>;
  graduationVideo?: Maybe<GraduationVideoType>;
  groups: MethodistGroupTypeConnection;
  id: Scalars['ID'];
  marketingModules: Array<CourseMarketingModuleType>;
  modules: Array<CourseModuleType>;
  name: Scalars['String'];
  price: Scalars['CustomFloat'];
  prices: Array<CoursePriceType>;
  publishDate: Scalars['Date'];
  readyToPublish: Scalars['Boolean'];
  salary?: Maybe<SalaryInfoType>;
  slug: Scalars['String'];
  teacherWeb?: Maybe<TeacherWebType>;
  teachers?: Maybe<TeacherFilterTypeConnection>;
  title?: Maybe<Scalars['String']>;
  videoTrailer?: Maybe<VideoTrailerType>;
};


export type CourseFilterTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  freePlacesGt?: InputMaybe<Scalars['Float']>;
  hideCompleted?: InputMaybe<Scalars['Date']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CourseFilterTypeTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  hasInterview?: InputMaybe<Scalars['Boolean']>;
  hasPhoto?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteDisplay?: InputMaybe<Scalars['Boolean']>;
};

export type CourseFilterTypeConnection = {
  __typename?: 'CourseFilterTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseFilterTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CourseFilterType` and its cursor. */
export type CourseFilterTypeEdge = {
  __typename?: 'CourseFilterTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CourseType>;
};

export type CourseLessonType = Node & {
  __typename?: 'CourseLessonType';
  courseProgram?: Maybe<EducationProgramType>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  lmsDescription: Scalars['String'];
  marketingModule?: Maybe<CourseMarketingModuleType>;
  module: CourseModuleType;
  name: Scalars['String'];
  number: Scalars['Int'];
  studentAttachments: StudentLessonAttachmentsTypeConnection;
  taskTypes: Array<Scalars['String']>;
  teacherAttachments: TeacherLessonAttachmentsTypeConnection;
};


export type CourseLessonTypeStudentAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CourseLessonTypeTeacherAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CourseLessonTypeConnection = {
  __typename?: 'CourseLessonTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseLessonTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CourseLessonType` and its cursor. */
export type CourseLessonTypeEdge = {
  __typename?: 'CourseLessonTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<CourseLessonType>;
};

export type CourseMarketingModuleType = {
  __typename?: 'CourseMarketingModuleType';
  course: CourseFilterType;
  description: Scalars['String'];
  id: Scalars['ID'];
  lessons: CourseLessonTypeConnection;
  name: Scalars['String'];
};


export type CourseMarketingModuleTypeLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CourseModuleType = {
  __typename?: 'CourseModuleType';
  course: CourseFilterType;
  id: Scalars['ID'];
  lessons: CourseLessonTypeConnection;
  name: Scalars['String'];
  number: Scalars['Int'];
};


export type CourseModuleTypeLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CoursePriceType = {
  __typename?: 'CoursePriceType';
  amount: Scalars['Float'];
  course: CourseFilterType;
  currency?: Maybe<CurrencyType>;
  currencyTicker: Scalars['String'];
  id: Scalars['ID'];
};

export type CourseRequirementsIntoType = {
  __typename?: 'CourseRequirementsIntoType';
  course?: Maybe<CourseFilterType>;
  courseRequirements: Array<RequirementsType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type CourseType = Node & {
  __typename?: 'CourseType';
  category?: Maybe<CategoryType>;
  ceoInfo?: Maybe<CeoInfoType>;
  contributions: Array<CourseContributionType>;
  courseBanner?: Maybe<CourseBanner>;
  courseDiscount?: Maybe<Scalars['Int']>;
  courseIcon?: Maybe<Scalars['String']>;
  courseRequirementsInfo?: Maybe<CourseRequirementsIntoType>;
  currency?: Maybe<Scalars['String']>;
  curriculumVitae?: Maybe<StudentCvType>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  duration: Scalars['String'];
  educationForm?: Maybe<Scalars['String']>;
  educationprogram?: Maybe<EducationProgramType>;
  futureKnowledge?: Maybe<FutureKnowledgeType>;
  futureProfession?: Maybe<FutureProfessionType>;
  graduateFeedbacks: Array<GraduateFeedbackType>;
  graduationVideo?: Maybe<GraduationVideoType>;
  groups: MethodistGroupTypeConnection;
  id: Scalars['ID'];
  marketingModules: Array<CourseMarketingModuleType>;
  modules: Array<CourseModuleType>;
  name: Scalars['String'];
  price: Scalars['CustomFloat'];
  prices: Array<CoursePriceType>;
  publishDate: Scalars['Date'];
  readyToPublish: Scalars['Boolean'];
  salary?: Maybe<SalaryInfoType>;
  slug: Scalars['String'];
  teacherWeb?: Maybe<TeacherWebType>;
  teachers?: Maybe<TeacherFilterTypeConnection>;
  title?: Maybe<Scalars['String']>;
  videoTrailer?: Maybe<VideoTrailerType>;
};


export type CourseTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  freePlacesGt?: InputMaybe<Scalars['Float']>;
  hideCompleted?: InputMaybe<Scalars['Date']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CourseTypeTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  hasInterview?: InputMaybe<Scalars['Boolean']>;
  hasPhoto?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteDisplay?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAgreementInput = {
  agreementTitle: Scalars['String'];
  companyComment?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  contributions?: InputMaybe<Array<InputMaybe<ContributionInput>>>;
  countryId?: InputMaybe<Scalars['ID']>;
  currency: Scalars['String'];
  date: Scalars['Date'];
  firstLesson?: InputMaybe<Scalars['ID']>;
  groupId: Scalars['ID'];
  lastLesson?: InputMaybe<Scalars['ID']>;
  partialAttendance?: InputMaybe<Scalars['Boolean']>;
  paymentByCompany?: InputMaybe<Scalars['Boolean']>;
  promocode?: InputMaybe<Scalars['String']>;
  resident?: InputMaybe<Scalars['Boolean']>;
  startAgreementBudget?: InputMaybe<Scalars['Float']>;
  studentId: Scalars['ID'];
  usedPayables?: InputMaybe<Array<Scalars['ID']>>;
  vat?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAgreementInputBase = {
  agreementTitle: Scalars['String'];
  companyComment?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  contributions?: InputMaybe<Array<InputMaybe<ContributionInput>>>;
  countryId?: InputMaybe<Scalars['ID']>;
  currency: Scalars['String'];
  date: Scalars['Date'];
  firstLesson?: InputMaybe<Scalars['ID']>;
  groupId: Scalars['ID'];
  lastLesson?: InputMaybe<Scalars['ID']>;
  partialAttendance?: InputMaybe<Scalars['Boolean']>;
  paymentByCompany?: InputMaybe<Scalars['Boolean']>;
  promocode?: InputMaybe<Scalars['String']>;
  resident?: InputMaybe<Scalars['Boolean']>;
  startAgreementBudget?: InputMaybe<Scalars['Float']>;
  usedPayables?: InputMaybe<Array<Scalars['ID']>>;
  vat?: InputMaybe<Scalars['Boolean']>;
};

export type CreateAgreementMutation = {
  __typename?: 'CreateAgreementMutation';
  agreement?: Maybe<AgreementType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateBlackFridayLeadMutation = {
  __typename?: 'CreateBlackFridayLeadMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCompanyMutation = {
  __typename?: 'CreateCompanyMutation';
  company?: Maybe<CompanyType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCourseMutation = {
  __typename?: 'CreateCourseMutation';
  course?: Maybe<CourseType>;
};

export type CreateDeferredPaymentsMutation = {
  __typename?: 'CreateDeferredPaymentsMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  deferredPayments?: Maybe<Array<Maybe<DeferredPaymentType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateGroupMutation = {
  __typename?: 'CreateGroupMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  group?: Maybe<GroupType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateLeadMutation = {
  __typename?: 'CreateLeadMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateNewYearLeadMutation = {
  __typename?: 'CreateNewYearLeadMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePaymentsMutation = {
  __typename?: 'CreatePaymentsMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  payments?: Maybe<Array<Maybe<PaymentType>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateUniqueTeacher = {
  __typename?: 'CreateUniqueTeacher';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<TeacherType>;
};

/** An enumeration. */
export enum Currencies {
  Byn = 'BYN',
  Eur = 'EUR',
  Rub = 'RUB',
  Usd = 'USD'
}

export type CurrencyType = {
  __typename?: 'CurrencyType';
  coursecontributionpriceSet: Array<CourseContributionPriceType>;
  coursepriceSet: Array<CoursePriceType>;
  id: Scalars['ID'];
  ticker: Scalars['String'];
};

export type CustomErrorType = {
  __typename?: 'CustomErrorType';
  code?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/**
 * Obtain JSON web token for given user.
 *
 * Allow to perform login with different fields,
 * and secondary email if set. The fields are
 * defined on settings.
 *
 * Not verified users can login by default. This
 * can be changes on settings.
 *
 * If user is archived, make it unarchive and
 * return `unarchiving=True` on output.
 */
export type CustomObtainJsonWebToken = {
  __typename?: 'CustomObtainJSONWebToken';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  unarchiving?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserNode>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type CustomRefreshToken = {
  __typename?: 'CustomRefreshToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  payload?: Maybe<Scalars['GenericScalar']>;
  refreshToken?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type CustomSendPasswordResetEmail = {
  __typename?: 'CustomSendPasswordResetEmail';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CustomUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  roles: Scalars['String'];
};

export type CustomUserType = Node & {
  __typename?: 'CustomUserType';
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  student?: Maybe<StudentType>;
  teacher?: Maybe<TeacherFilterType>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
};

export type CvDateInput = {
  month?: InputMaybe<Scalars['String']>;
  year: Scalars['String'];
};

export type DataProcessingPolicyAgreementType = Node & {
  __typename?: 'DataProcessingPolicyAgreementType';
  agreementDate: Scalars['Date'];
  content: Scalars['String'];
  displayPage: DataProcessingPolicyType;
  file: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type DataProcessingPolicyAgreementTypeConnection = {
  __typename?: 'DataProcessingPolicyAgreementTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DataProcessingPolicyAgreementTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DataProcessingPolicyAgreementType` and its cursor. */
export type DataProcessingPolicyAgreementTypeEdge = {
  __typename?: 'DataProcessingPolicyAgreementTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DataProcessingPolicyAgreementType>;
};

export type DataProcessingPolicyType = {
  __typename?: 'DataProcessingPolicyType';
  actualPublicOfferAgreement?: Maybe<DataProcessingPolicyAgreementType>;
  id: Scalars['ID'];
  publicOfferAgreements: DataProcessingPolicyAgreementTypeConnection;
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};


export type DataProcessingPolicyTypePublicOfferAgreementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type DayOfClassInput = {
  endTime?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<Scalars['Time']>;
  weekDay: Scalars['Int'];
};

export type DayOfClassType = {
  __typename?: 'DayOfClassType';
  endTime: Scalars['Time'];
  group: MethodistGroupType;
  id: Scalars['ID'];
  startTime: Scalars['Time'];
  weekDay: Scalars['Int'];
};

export type DeferredPaymentConnection = {
  __typename?: 'DeferredPaymentConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeferredPaymentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum DeferredPaymentCurrency {
  /** BYN */
  Byn = 'BYN',
  /** EUR */
  Eur = 'EUR',
  /** RUB */
  Rub = 'RUB',
  /** USD */
  Usd = 'USD'
}

/** A Relay edge containing a `DeferredPayment` and its cursor. */
export type DeferredPaymentEdge = {
  __typename?: 'DeferredPaymentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<DeferredPaymentType>;
};

export type DeferredPaymentInput = {
  agreementTitle?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  deferredPaymentId?: InputMaybe<Scalars['ID']>;
  studentFullname?: InputMaybe<Scalars['String']>;
  sum?: InputMaybe<Scalars['Float']>;
  userInput: Scalars['String'];
};

export type DeferredPaymentType = Node & {
  __typename?: 'DeferredPaymentType';
  agreementCurrency?: Maybe<Scalars['String']>;
  agreementId?: Maybe<Scalars['Int']>;
  agreementTitle?: Maybe<Scalars['String']>;
  currency?: Maybe<DeferredPaymentCurrency>;
  date: Scalars['Date'];
  id: Scalars['ID'];
  studentFullname?: Maybe<Scalars['String']>;
  sum?: Maybe<Scalars['CustomFloat']>;
  userInput: Scalars['String'];
};

export type DeleteCourseMutation = {
  __typename?: 'DeleteCourseMutation';
  course?: Maybe<CourseType>;
};

export type DeleteGroupMutation = {
  __typename?: 'DeleteGroupMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteTeacherApplication = {
  __typename?: 'DeleteTeacherApplication';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type EducationInCvType = {
  __typename?: 'EducationInCVType';
  degree?: Maybe<Scalars['String']>;
  edInstitution?: Maybe<Scalars['String']>;
  endDateMonth?: Maybe<Scalars['String']>;
  endDateYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  specialization?: Maybe<Scalars['String']>;
  startDateMonth?: Maybe<Scalars['String']>;
  startDateYear?: Maybe<Scalars['Int']>;
  studentCv: StudentResumeType;
};

export type EducationInput = {
  degree?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<CvDateInput>;
  institution?: InputMaybe<Scalars['String']>;
  specialization?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<CvDateInput>;
};

export type EducationProgramType = {
  __typename?: 'EducationProgramType';
  course: CourseFilterType;
  description: Scalars['String'];
  id: Scalars['ID'];
  lessons: CourseLessonTypeConnection;
  numberOfHours: Scalars['String'];
  title: Scalars['String'];
};


export type EducationProgramTypeLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type EmploymentInputType = {
  individualEntrepreneur?: InputMaybe<IndividualEntrepreneurInput>;
  legalEntity?: InputMaybe<LegalEntityInput>;
  naturalPerson?: InputMaybe<NaturalPersonInput>;
  selfEmployed?: InputMaybe<SelfEmployedInput>;
};

export type EmploymentType = IndividualEntrepreneurType | LegalEntityType | NaturalPersonType | SelfEmployedType;

/** An enumeration. */
export enum EmploymentTypes {
  /** individual_entrepreneur */
  Ie = 'IE',
  /** legal_entity */
  Le = 'LE',
  /** natural_person */
  Np = 'NP',
  /** self_employed */
  Se = 'SE'
}

export type ExistEmploymentInputType = {
  individualEntrepreneur?: InputMaybe<ExistIndividualEntrepreneurInput>;
  legalEntity?: InputMaybe<ExistLegalEntityInput>;
  naturalPerson?: InputMaybe<ExistNaturalPersonInput>;
  selfEmployed?: InputMaybe<ExistSelfEmployedInput>;
};

export type ExistIndividualEntrepreneurInput = {
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  existId?: InputMaybe<Scalars['ID']>;
  inn: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type ExistLegalEntityInput = {
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  existId?: InputMaybe<Scalars['ID']>;
  inn: Scalars['String'];
  registrationAddress: Scalars['String'];
  tittle: Scalars['String'];
};

export type ExistNaturalPersonInput = {
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  existId?: InputMaybe<Scalars['ID']>;
  nationality: Scalars['ID'];
  passportNumber: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type ExistSelfEmployedInput = {
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  existId?: InputMaybe<Scalars['ID']>;
  inn: Scalars['String'];
  nationality: Scalars['ID'];
  passportNumber: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type FinishLmsLessonMutation = {
  __typename?: 'FinishLmsLessonMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type FutureKnowledgeType = {
  __typename?: 'FutureKnowledgeType';
  course?: Maybe<CourseFilterType>;
  descriptions: Array<KnowledgeDescriptionType>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type FutureProfessionType = {
  __typename?: 'FutureProfessionType';
  course?: Maybe<CourseFilterType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type GraduateFeedbackConnection = {
  __typename?: 'GraduateFeedbackConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GraduateFeedbackEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `GraduateFeedback` and its cursor. */
export type GraduateFeedbackEdge = {
  __typename?: 'GraduateFeedbackEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GraduateFeedbackType>;
};

export type GraduateFeedbackType = {
  __typename?: 'GraduateFeedbackType';
  course: CourseFilterTypeConnection;
  date: Scalars['Date'];
  feedback: Scalars['String'];
  graduateName: Scalars['String'];
  graduatePhoto?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resourceIcon: Scalars['String'];
};


export type GraduateFeedbackTypeCourseArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category_Name?: InputMaybe<Scalars['String']>;
  educationForm?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderByPublishDateFilter?: InputMaybe<Scalars['Boolean']>;
  readyToPublish?: InputMaybe<Scalars['Boolean']>;
  startDateFilter?: InputMaybe<Scalars['Boolean']>;
};

export type GraduationVideoType = {
  __typename?: 'GraduationVideoType';
  course?: Maybe<CourseFilterType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type GroupConnection = {
  __typename?: 'GroupConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Group` and its cursor. */
export type GroupEdge = {
  __typename?: 'GroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GroupType>;
};

export type GroupInput = {
  course_id: Scalars['Int'];
  currency: Scalars['String'];
  daysOfClass: Array<DayOfClassInput>;
  name: Scalars['String'];
  price: Scalars['Float'];
  startDate: Scalars['Date'];
};

export type GroupLessonConnection = {
  __typename?: 'GroupLessonConnection';
  comment?: Maybe<Scalars['String']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupLessonEdge>>;
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GroupLesson` and its cursor. */
export type GroupLessonEdge = {
  __typename?: 'GroupLessonEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GroupLessonType>;
};

export type GroupLessonForLmsType = Node & {
  __typename?: 'GroupLessonForLMSType';
  additional?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  endTime: Scalars['Time'];
  finished: Scalars['Boolean'];
  hasStudentAttachment?: Maybe<Scalars['Boolean']>;
  hasStudentMarks?: Maybe<Scalars['Boolean']>;
  hasTeacherAttachment?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  lessonNumber?: Maybe<Scalars['Int']>;
  lmsDescription?: Maybe<Scalars['String']>;
  marks: StudentsMarkTypeConnection;
  module: GroupModuleType;
  name: Scalars['String'];
  needToFinish?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  startTime: Scalars['Time'];
  students: StudentTypeConnection;
  taskTypes: Array<Scalars['String']>;
  teacher?: Maybe<TeacherType>;
  teacherApplication?: Maybe<ApplicationType>;
  video?: Maybe<Scalars['String']>;
};


export type GroupLessonForLmsTypeMarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lessonId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  taskType?: InputMaybe<Scalars['String']>;
};


export type GroupLessonForLmsTypeStudentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GroupLessonType = Node & {
  __typename?: 'GroupLessonType';
  additional?: Maybe<Scalars['Boolean']>;
  attendances: AttendanceTypeConnection;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  endTime: Scalars['Time'];
  finished: Scalars['Boolean'];
  hasStudentMarks?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  lessonNumber?: Maybe<Scalars['Int']>;
  lmsDescription?: Maybe<Scalars['String']>;
  marks: StudentsMarkTypeConnection;
  module: GroupModuleType;
  name: Scalars['String'];
  needToFinish?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  startTime: Scalars['Time'];
  students: StudentTypeConnection;
  studentsAttachments: StudentLessonAttachmentTypeConnection;
  taskTypes: Array<Scalars['String']>;
  teacher?: Maybe<TeacherType>;
  teacherApplication?: Maybe<ApplicationType>;
  teachersAttachments: TeacherLessonAttachmentTypeConnection;
  video?: Maybe<Scalars['String']>;
};


export type GroupLessonTypeAttendancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GroupLessonTypeMarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lessonId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  taskType?: InputMaybe<Scalars['String']>;
};


export type GroupLessonTypeStudentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GroupLessonTypeStudentsAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GroupLessonTypeTeachersAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GroupLessonTypeConnection = {
  __typename?: 'GroupLessonTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupLessonTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GroupLessonType` and its cursor. */
export type GroupLessonTypeEdge = {
  __typename?: 'GroupLessonTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GroupLessonType>;
};

export type GroupModuleType = {
  __typename?: 'GroupModuleType';
  group: MethodistGroupType;
  id: Scalars['ID'];
  lessons: GroupLessonTypeConnection;
  name: Scalars['String'];
};


export type GroupModuleTypeLessonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export type GroupType = Node & {
  __typename?: 'GroupType';
  agreements: Array<AgreementType>;
  classesDays: Array<DayOfClassType>;
  comment?: Maybe<Scalars['String']>;
  contributionsSum?: Maybe<Scalars['CustomFloat']>;
  course: CourseFilterType;
  currency?: Maybe<Scalars['String']>;
  datetimeNextLesson?: Maybe<Scalars['DateTime']>;
  daysOfClass: Array<Scalars['Int']>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  freeLessonsCount?: Maybe<Scalars['Int']>;
  freePlaces?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lessons?: Maybe<Array<GroupLessonType>>;
  lessonsCount?: Maybe<Scalars['Int']>;
  maxStudentsCount: Scalars['Int'];
  modules: Array<GroupModuleType>;
  name: Scalars['String'];
  passedLessonsCount?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['CustomFloat']>;
  publishedLms: Scalars['Boolean'];
  publishedLmsForMethodist: Scalars['Boolean'];
  publishedLmsForTeacher: Scalars['Boolean'];
  startDate: Scalars['Date'];
  statusFinished?: Maybe<Scalars['Boolean']>;
  studentsCount?: Maybe<Scalars['Int']>;
  teacherLeads: ApplicationFilterTypeConnection;
  teachers?: Maybe<Array<Maybe<TeacherType>>>;
};


export type GroupTypeTeacherLeadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  byGroupId?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  isTerminated?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type GroupsType = {
  __typename?: 'GroupsType';
  agreements: Array<AgreementType>;
  classesDays: Array<DayOfClassType>;
  comment?: Maybe<Scalars['String']>;
  course: CourseFilterType;
  id: Scalars['ID'];
  maxStudentsCount: Scalars['Int'];
  modules: Array<GroupModuleType>;
  name: Scalars['String'];
  publishedLms: Scalars['Boolean'];
  publishedLmsForMethodist: Scalars['Boolean'];
  publishedLmsForTeacher: Scalars['Boolean'];
  startDate: Scalars['Date'];
  teacherLeads: ApplicationFilterTypeConnection;
};


export type GroupsTypeTeacherLeadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  byGroupId?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  isTerminated?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type IndividualEntrepreneurInput = {
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  inn: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type IndividualEntrepreneurType = Node & {
  __typename?: 'IndividualEntrepreneurType';
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  id: Scalars['ID'];
  inn: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type KnowledgeDescriptionType = {
  __typename?: 'KnowledgeDescriptionType';
  description: Scalars['String'];
  futureKnowledge: FutureKnowledgeType;
  id: Scalars['ID'];
};

export type LmsAuthenticationInput = {
  email: Scalars['String'];
  scope: Scalars['String'];
};

export type LanguageConnection = {
  __typename?: 'LanguageConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LanguageEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Language` and its cursor. */
export type LanguageEdge = {
  __typename?: 'LanguageEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<LanguageType>;
};

/** An enumeration. */
export enum LanguageEnum {
  /** ENG */
  Eng = 'ENG',
  /** RUS */
  Rus = 'RUS'
}

export type LanguageType = {
  __typename?: 'LanguageType';
  id: Scalars['ID'];
  language: Scalars['String'];
  languagesincvSet: Array<LanguagesInCvType>;
};

/** An enumeration. */
export enum LanguagesInCvLanguageLevel {
  /** A1 */
  A1Elementary = 'A1_ELEMENTARY',
  /** A2 */
  A2PreIntermediate = 'A2_PRE_INTERMEDIATE',
  /** B1 */
  B1Intermediate = 'B1_INTERMEDIATE',
  /** B2 */
  B2UpperIntermediate = 'B2_UPPER_INTERMEDIATE',
  /** C1 */
  C1Advanced = 'C1_ADVANCED',
  /** C2 */
  C2Proficiency = 'C2_PROFICIENCY',
  /** Native */
  Native = 'NATIVE'
}

export type LanguagesInCvType = {
  __typename?: 'LanguagesInCVType';
  id: Scalars['ID'];
  language: LanguageType;
  languageLevel: LanguagesInCvLanguageLevel;
  studentCv: StudentResumeType;
};

export type LanguagesInput = {
  language: Scalars['String'];
  languageLevel: LanguagesLevelEnum;
};

/** An enumeration. */
export enum LanguagesLevelEnum {
  /** A1 Elementary */
  A1 = 'A1',
  /** A2 Pre-Intermediate */
  A2 = 'A2',
  /** B1 Intermediate */
  B1 = 'B1',
  /** B2 Upper-Intermediate */
  B2 = 'B2',
  /** C1 Advanced */
  C1 = 'C1',
  /** C2 Proficiency */
  C2 = 'C2',
  /** Native */
  Native = 'NATIVE'
}

export type LeadInput = {
  course_id?: InputMaybe<Scalars['Int']>;
  formName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  promocode?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  roistatId: Scalars['String'];
  tittle: Scalars['String'];
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

export type LegalEntityInput = {
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  inn: Scalars['String'];
  registrationAddress: Scalars['String'];
  tittle: Scalars['String'];
};

export type LegalEntityPublicOfferAgreementType = Node & {
  __typename?: 'LegalEntityPublicOfferAgreementType';
  agreementDate: Scalars['Date'];
  content: Scalars['String'];
  displayPage: LegalEntityPublicOfferPageType;
  file: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type LegalEntityPublicOfferAgreementTypeConnection = {
  __typename?: 'LegalEntityPublicOfferAgreementTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LegalEntityPublicOfferAgreementTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LegalEntityPublicOfferAgreementType` and its cursor. */
export type LegalEntityPublicOfferAgreementTypeEdge = {
  __typename?: 'LegalEntityPublicOfferAgreementTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<LegalEntityPublicOfferAgreementType>;
};

export type LegalEntityPublicOfferPageType = {
  __typename?: 'LegalEntityPublicOfferPageType';
  actualPublicOfferAgreement?: Maybe<LegalEntityPublicOfferAgreementType>;
  id: Scalars['ID'];
  publicOfferAgreements: LegalEntityPublicOfferAgreementTypeConnection;
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};


export type LegalEntityPublicOfferPageTypePublicOfferAgreementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type LegalEntityType = Node & {
  __typename?: 'LegalEntityType';
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  id: Scalars['ID'];
  inn: Scalars['String'];
  registrationAddress: Scalars['String'];
  tittle: Scalars['String'];
};

export type LessonAttachmentsForLmsType = {
  __typename?: 'LessonAttachmentsForLMSType';
  file: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type LessonAttachmentsType = {
  __typename?: 'LessonAttachmentsType';
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type LessonAttendancesType = Node & {
  __typename?: 'LessonAttendancesType';
  attendance?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lesson: GroupLessonType;
  student: StudentType;
};

export type LessonInput = {
  additional?: InputMaybe<Scalars['Boolean']>;
  lmsDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  studentsAttachments?: InputMaybe<Array<Scalars['Int']>>;
  taskTypes?: InputMaybe<Array<Scalars['String']>>;
  teachersAttachments?: InputMaybe<Array<Scalars['Int']>>;
};

export type LessonMarkInput = {
  hwMark?: InputMaybe<StudentHwTypeEnum>;
  mark?: InputMaybe<Scalars['Int']>;
  student_id: Scalars['Int'];
};

/** An enumeration. */
export enum LessonTaskTypeEnum {
  /** coursework */
  Coursework = 'coursework',
  /** graduation_project */
  GraduationProject = 'graduation_project',
  /** homework */
  Homework = 'homework'
}

export type LmsAttendanceType = {
  attendance: Scalars['Boolean'];
  id: Scalars['Int'];
};

/** An enumeration. */
export enum LmsRoles {
  /** Methodist */
  Methodist = 'METHODIST',
  /** Student */
  Student = 'STUDENT',
  /** Teacher */
  Teacher = 'TEACHER'
}

export type MainPageInfoType = {
  __typename?: 'MainPageInfoType';
  description: Scalars['String'];
  id: Scalars['ID'];
  image: Scalars['String'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};

export type MainTeacherWebType = Node & {
  __typename?: 'MainTeacherWebType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mainTeachers: TeacherFilterTypeConnection;
  title?: Maybe<Scalars['String']>;
};


export type MainTeacherWebTypeMainTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  hasInterview?: InputMaybe<Scalars['Boolean']>;
  hasPhoto?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteDisplay?: InputMaybe<Scalars['Boolean']>;
};

export type MainTeacherWebTypeConnection = {
  __typename?: 'MainTeacherWebTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MainTeacherWebTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MainTeacherWebType` and its cursor. */
export type MainTeacherWebTypeEdge = {
  __typename?: 'MainTeacherWebTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<MainTeacherWebType>;
};

export type MetaContribution = {
  __typename?: 'MetaContribution';
  /** Deprecated from 1.3.0 version. */
  contributionsCount?: Maybe<Scalars['Int']>;
  contributionsNums: Array<Maybe<Scalars['Int']>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MetaCourses = {
  __typename?: 'MetaCourses';
  totalCount?: Maybe<Scalars['Int']>;
};

export type MetaDeferredPayment = Node & {
  __typename?: 'MetaDeferredPayment';
  deferredPaymentsDate?: Maybe<Scalars['Date']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  totalCount?: Maybe<Scalars['Int']>;
};

export type MetaDeferredPaymentConnection = {
  __typename?: 'MetaDeferredPaymentConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MetaDeferredPaymentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MetaDeferredPayment` and its cursor. */
export type MetaDeferredPaymentEdge = {
  __typename?: 'MetaDeferredPaymentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<MetaDeferredPayment>;
};

export type MetaGroups = {
  __typename?: 'MetaGroups';
  totalCount?: Maybe<Scalars['Int']>;
};

export type MetaStudents = {
  __typename?: 'MetaStudents';
  agreementsCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MetaTeacherApplications = {
  __typename?: 'MetaTeacherApplications';
  totalCount?: Maybe<Scalars['Int']>;
};

export type MetaTeachers = {
  __typename?: 'MetaTeachers';
  /** Returns applications count */
  applicationsCount?: Maybe<Scalars['Int']>;
  /** Returns teachers count */
  totalCount?: Maybe<Scalars['Int']>;
};

export type MetaUsersSettings = {
  __typename?: 'MetaUsersSettings';
  totalCount?: Maybe<Scalars['Int']>;
};

export type MethodistGroupType = Node & {
  __typename?: 'MethodistGroupType';
  agreements: Array<AgreementType>;
  classesDays: Array<DayOfClassType>;
  comment?: Maybe<Scalars['String']>;
  contributionsSum?: Maybe<Scalars['CustomFloat']>;
  course: CourseFilterType;
  currency?: Maybe<Scalars['String']>;
  datetimeNextLesson?: Maybe<Scalars['DateTime']>;
  daysOfClass: Array<Scalars['Int']>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  freeLessonsCount?: Maybe<Scalars['Int']>;
  freePlaces?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lessons?: Maybe<Array<GroupLessonType>>;
  lessonsCount?: Maybe<Scalars['Int']>;
  maxStudentsCount: Scalars['Int'];
  modules: Array<GroupModuleType>;
  name: Scalars['String'];
  passedLessonsCount?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['CustomFloat']>;
  publishedLms: Scalars['Boolean'];
  publishedLmsForMethodist: Scalars['Boolean'];
  publishedLmsForTeacher: Scalars['Boolean'];
  startDate: Scalars['Date'];
  statusFinished?: Maybe<Scalars['Boolean']>;
  studentsCount?: Maybe<Scalars['Int']>;
  teacherLeads: ApplicationFilterTypeConnection;
  teachers?: Maybe<Array<Maybe<TeacherType>>>;
};


export type MethodistGroupTypeTeacherLeadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  byGroupId?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  isTerminated?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type MethodistGroupTypeConnection = {
  __typename?: 'MethodistGroupTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MethodistGroupTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MethodistGroupType` and its cursor. */
export type MethodistGroupTypeEdge = {
  __typename?: 'MethodistGroupTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<MethodistGroupType>;
};

export type MethodistLessonType = Node & {
  __typename?: 'MethodistLessonType';
  additional?: Maybe<Scalars['Boolean']>;
  attendances: AttendanceTypeConnection;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  endTime: Scalars['Time'];
  finished: Scalars['Boolean'];
  firstLessonAgreements: Array<AgreementType>;
  hasStudentAttachment?: Maybe<Scalars['Boolean']>;
  hasStudentMarks?: Maybe<Scalars['Boolean']>;
  hasTeacherAttachment?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  lastLessonAgreements: Array<AgreementType>;
  lessonNumber?: Maybe<Scalars['Int']>;
  lmsDescription?: Maybe<Scalars['String']>;
  marks: StudentsMarkTypeConnection;
  module: GroupModuleType;
  name: Scalars['String'];
  needToFinish?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  startTime: Scalars['Time'];
  students: StudentTypeConnection;
  studentsAttachments: StudentLessonAttachmentTypeConnection;
  taskTypes: Array<Scalars['String']>;
  teacher?: Maybe<TeacherType>;
  teacherApplication?: Maybe<ApplicationType>;
  teachersAttachments: TeacherLessonAttachmentTypeConnection;
  video?: Maybe<Scalars['String']>;
};


export type MethodistLessonTypeAttendancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type MethodistLessonTypeMarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lessonId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  taskType?: InputMaybe<Scalars['String']>;
};


export type MethodistLessonTypeStudentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type MethodistLessonTypeStudentsAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type MethodistLessonTypeTeachersAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ModuleInput = {
  lessons: Array<InputMaybe<LessonInput>>;
  name: Scalars['String'];
};

export type MonthBudget = {
  __typename?: 'MonthBudget';
  BYN?: Maybe<Scalars['CustomFloat']>;
  EUR?: Maybe<Scalars['CustomFloat']>;
  RUB?: Maybe<Scalars['CustomFloat']>;
  USD?: Maybe<Scalars['CustomFloat']>;
  month?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addTeacherApplication?: Maybe<AddTeacherApplication>;
  addTeacherExist?: Maybe<AddTeacherExist>;
  addTeacherRole?: Maybe<AddNewRoleTeacher>;
  appendRolesUserSettings?: Maybe<UserSettingsRolesAddMutation>;
  appendStudentRoleAndAgreement?: Maybe<AddRoleStudentAndAgreement>;
  cancelVideoUpload?: Maybe<CancelVideoUpload>;
  changeLessonDates?: Maybe<ChangeLessonDate>;
  checkStudentDebt?: Maybe<CheckStudentsDebtMutation>;
  createAgreement?: Maybe<CreateAgreementMutation>;
  createBlackFridayLead?: Maybe<CreateBlackFridayLeadMutation>;
  createCompany?: Maybe<CreateCompanyMutation>;
  createCourse?: Maybe<CreateCourseMutation>;
  createDeferredPayments?: Maybe<CreateDeferredPaymentsMutation>;
  createGroup?: Maybe<CreateGroupMutation>;
  createLead?: Maybe<CreateLeadMutation>;
  createNewYearLead?: Maybe<CreateNewYearLeadMutation>;
  createPayments?: Maybe<CreatePaymentsMutation>;
  createStudentWithAgreement?: Maybe<StudentWithAgreementCreateMutation>;
  createUniqueTeacher?: Maybe<CreateUniqueTeacher>;
  createUser?: Maybe<RegisterMutation>;
  /**
   * Obtain JSON web token for given user.
   *
   * Allow to perform login with different fields,
   * and secondary email if set. The fields are
   * defined on settings.
   *
   * Not verified users can login by default. This
   * can be changes on settings.
   *
   * If user is archived, make it unarchive and
   * return `unarchiving=True` on output.
   */
  customLogin?: Maybe<CustomObtainJsonWebToken>;
  deleteCourse?: Maybe<DeleteCourseMutation>;
  deleteGroup?: Maybe<DeleteGroupMutation>;
  deleteTeacherApplication?: Maybe<DeleteTeacherApplication>;
  deleteUserSettings?: Maybe<UserSettingsDeleteMutation>;
  finishLmsLesson?: Maybe<FinishLmsLessonMutation>;
  /**
   * Change user password without old password.
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, update
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordReset?: Maybe<PasswordReset>;
  /**
   * Set user password - for passwordless registration
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, set
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordSet?: Maybe<PasswordSet>;
  publishGroup?: Maybe<PublishGroupToLms>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  refreshToken?: Maybe<CustomRefreshToken>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  revokeToken?: Maybe<RevokeToken>;
  sendLmsAuthEmail?: Maybe<SendAuthenticationCode>;
  sendPasswordResetEmail?: Maybe<CustomSendPasswordResetEmail>;
  setStudentsMarks?: Maybe<SetStudentsMarksMutation>;
  updateAttendance?: Maybe<UpdateAttendanceMutation>;
  updateCompany?: Maybe<UpdateCompanyMutation>;
  updateCourse?: Maybe<UpdateCourseMutation>;
  updateGroup?: Maybe<UpdateGroupMutation>;
  updateJournal?: Maybe<UpdateJournalsMutation>;
  updateStudentWithAgreement?: Maybe<StudentWithAgreementUpdateMutation>;
  updateTeacherApplication?: Maybe<UpdateTeacherApplication>;
  updateTeacherWithType?: Maybe<UpdateTeacherWithTypeMutation>;
  updateUserSettings?: Maybe<UserSettingsUpdateMutation>;
  uploadLessonVideo?: Maybe<UploadLessonVideo>;
  uploadResume?: Maybe<UploadResume>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  verifyToken?: Maybe<VerifyToken>;
};


export type MutationAddTeacherApplicationArgs = {
  application: ApplicationInputType;
  teacherId: Scalars['ID'];
};


export type MutationAddTeacherExistArgs = {
  application: ApplicationInputType;
  employmentTypes: ExistEmploymentInputType;
  teacherId: Scalars['ID'];
};


export type MutationAddTeacherRoleArgs = {
  application: ApplicationInputType;
  employmentTypes: EmploymentInputType;
  siteDisplay?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};


export type MutationAppendRolesUserSettingsArgs = {
  roles: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationAppendStudentRoleAndAgreementArgs = {
  agreementInput: CreateAgreementInputBase;
  userId: Scalars['ID'];
};


export type MutationCancelVideoUploadArgs = {
  lessonId: Scalars['Int'];
};


export type MutationChangeLessonDatesArgs = {
  groupId: Scalars['Int'];
  newDate: Scalars['Date'];
  oldDate: Scalars['Date'];
};


export type MutationCheckStudentDebtArgs = {
  agreementsInput: Array<InputMaybe<Scalars['String']>>;
};


export type MutationCreateAgreementArgs = {
  agreementInput: CreateAgreementInput;
};


export type MutationCreateBlackFridayLeadArgs = {
  bfLeadInput: BlackFridayLeadInput;
};


export type MutationCreateCompanyArgs = {
  companyInput: CompanyInput;
};


export type MutationCreateCourseArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDeferredPaymentsArgs = {
  deferredPaymentsInput?: InputMaybe<Array<InputMaybe<DeferredPaymentInput>>>;
};


export type MutationCreateGroupArgs = {
  groupInput: GroupInput;
  moduleInput: Array<InputMaybe<ModuleInput>>;
};


export type MutationCreateLeadArgs = {
  leadInput: LeadInput;
};


export type MutationCreateNewYearLeadArgs = {
  nyLeadInput: NewYearLeadInput;
};


export type MutationCreatePaymentsArgs = {
  paymentsInput?: InputMaybe<Array<InputMaybe<PaymentInput>>>;
};


export type MutationCreateStudentWithAgreementArgs = {
  agreementInput: CreateAgreementInputBase;
  studentInput: StudentInput;
};


export type MutationCreateUniqueTeacherArgs = {
  application: ApplicationInputType;
  employmentTypes: EmploymentInputType;
  teacher: NewTeacherInput;
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  roles: Scalars['String'];
};


export type MutationCustomLoginArgs = {
  authenticationCode?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  scope: Scalars['String'];
};


export type MutationDeleteCourseArgs = {
  courseId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['Int'];
};


export type MutationDeleteTeacherApplicationArgs = {
  applicationId: Scalars['ID'];
};


export type MutationDeleteUserSettingsArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationFinishLmsLessonArgs = {
  attendances: Array<LmsAttendanceType>;
  comment: Scalars['String'];
  groupLessonId: Scalars['Int'];
};


export type MutationPasswordResetArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};


export type MutationPasswordSetArgs = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};


export type MutationPublishGroupArgs = {
  groupId: Scalars['ID'];
  publishedForTeachers?: InputMaybe<Scalars['Boolean']>;
  publishedLms: Scalars['Boolean'];
  publishedLmsForMethodist?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRefreshTokenArgs = {
  activeRole?: InputMaybe<LmsRoles>;
  refreshToken: Scalars['String'];
  scope?: InputMaybe<Scalars['String']>;
};


export type MutationRevokeTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationSendLmsAuthEmailArgs = {
  authenticationInput: LmsAuthenticationInput;
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetStudentsMarksArgs = {
  date: Scalars['Date'];
  lessonId: Scalars['Int'];
  studentsMarks: Array<InputMaybe<LessonMarkInput>>;
  taskType: LessonTaskTypeEnum;
};


export type MutationUpdateAttendanceArgs = {
  attendance: Scalars['Boolean'];
  attendanceId: Scalars['Int'];
};


export type MutationUpdateCompanyArgs = {
  companyId: Scalars['ID'];
  companyInput: UpdateCompanyInput;
};


export type MutationUpdateCourseArgs = {
  courseId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['Int'];
  groupInput: UpdateGroupInput;
};


export type MutationUpdateJournalArgs = {
  comment: Scalars['String'];
  daysOfClass: Array<DayOfClassInput>;
  groupId: Scalars['Int'];
  modules: Array<InputMaybe<UpdateModuleInput>>;
};


export type MutationUpdateStudentWithAgreementArgs = {
  agreementId?: InputMaybe<Scalars['ID']>;
  updateStudentInput: UpdateStudentInput;
};


export type MutationUpdateTeacherApplicationArgs = {
  application: UpdateApplicationInputType;
};


export type MutationUpdateTeacherWithTypeArgs = {
  employmentInput: ExistEmploymentInputType;
  teacherId?: InputMaybe<Scalars['ID']>;
  teacherInput: TeacherWithTypeInput;
};


export type MutationUpdateUserSettingsArgs = {
  userId?: InputMaybe<Scalars['ID']>;
  userSettingsInput: CustomUserInput;
};


export type MutationUploadLessonVideoArgs = {
  lessonId: Scalars['Int'];
  videoUrl: Scalars['String'];
};


export type MutationUploadResumeArgs = {
  photo?: InputMaybe<Scalars['Upload']>;
  resumeInput: ResumeInput;
};


export type MutationVerifyTokenArgs = {
  token: Scalars['String'];
};

export type NativeInfoConnection = {
  __typename?: 'NativeInfoConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NativeInfoEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `NativeInfo` and its cursor. */
export type NativeInfoEdge = {
  __typename?: 'NativeInfoEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NativeInfoType>;
};

export type NativeInfoType = {
  __typename?: 'NativeInfoType';
  articlesSet: Array<ArticlesType>;
  date: Scalars['Date'];
  description: Scalars['String'];
  id: Scalars['ID'];
  image: Scalars['String'];
  interviewLink: Scalars['String'];
  interviewText: Scalars['String'];
  nativeBanner?: Maybe<CourseBanner>;
  published: Scalars['Boolean'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoSlug?: Maybe<Scalars['String']>;
  seoTitle: Scalars['String'];
  successstoriesSet: Array<SuccessStoriesType>;
  title: Scalars['String'];
};

export type NaturalPersonInput = {
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  nationality: Scalars['ID'];
  passportNumber: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type NaturalPersonType = Node & {
  __typename?: 'NaturalPersonType';
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  id: Scalars['ID'];
  nationality: CountryType;
  passportNumber: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type NewTeacherInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  siteDisplay?: InputMaybe<Scalars['Boolean']>;
};

export type NewYearLeadInput = {
  age: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  course_id?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  formName: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  promocode?: InputMaybe<Scalars['String']>;
  referer?: InputMaybe<Scalars['String']>;
  roistatId: Scalars['String'];
  tittle: Scalars['String'];
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/**
 * Change user password without old password.
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, update
 * user password and in case of using refresh
 * tokens, revoke all of them.
 *
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordReset = {
  __typename?: 'PasswordReset';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Set user password - for passwordless registration
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, set
 * user password and in case of using refresh
 * tokens, revoke all of them.
 *
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordSet = {
  __typename?: 'PasswordSet';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum PaymentCurrency {
  /** BYN */
  Byn = 'BYN',
  /** EUR */
  Eur = 'EUR',
  /** RUB */
  Rub = 'RUB',
  /** USD */
  Usd = 'USD'
}

export type PaymentInput = {
  agreement_id: Scalars['ID'];
  currency: Scalars['String'];
  date: Scalars['Date'];
  deferredPaymentId?: InputMaybe<Scalars['ID']>;
  sum: Scalars['Float'];
};

export type PaymentLogoType = {
  __typename?: 'PaymentLogoType';
  contactsPage: ContactsPageType;
  id: Scalars['ID'];
  logo: Scalars['String'];
};

export type PaymentRulesPageType = {
  __typename?: 'PaymentRulesPageType';
  id: Scalars['ID'];
  rules: Scalars['String'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};

export type PaymentType = {
  __typename?: 'PaymentType';
  agreement: AgreementType;
  amountAgreement: Scalars['Float'];
  amountByn?: Maybe<Scalars['CustomFloat']>;
  amountForeign?: Maybe<Scalars['CustomFloat']>;
  currency: PaymentCurrency;
  date: Scalars['Date'];
  id: Scalars['ID'];
};

export type PaymentsVerificationType = {
  __typename?: 'PaymentsVerificationType';
  agreementTitle?: Maybe<Scalars['String']>;
  agreement_id?: Maybe<Scalars['ID']>;
  currency?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['ID']>;
};

export type Person = {
  __typename?: 'Person';
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PrivateIndividualPublicOfferAgreementType = Node & {
  __typename?: 'PrivateIndividualPublicOfferAgreementType';
  agreementDate: Scalars['Date'];
  content: Scalars['String'];
  displayPage: PrivateIndividualPublicOfferPageType;
  file: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type PrivateIndividualPublicOfferAgreementTypeConnection = {
  __typename?: 'PrivateIndividualPublicOfferAgreementTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrivateIndividualPublicOfferAgreementTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PrivateIndividualPublicOfferAgreementType` and its cursor. */
export type PrivateIndividualPublicOfferAgreementTypeEdge = {
  __typename?: 'PrivateIndividualPublicOfferAgreementTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<PrivateIndividualPublicOfferAgreementType>;
};

export type PrivateIndividualPublicOfferPageType = {
  __typename?: 'PrivateIndividualPublicOfferPageType';
  actualPublicOfferAgreement?: Maybe<PrivateIndividualPublicOfferAgreementType>;
  id: Scalars['ID'];
  publicOfferAgreements: PrivateIndividualPublicOfferAgreementTypeConnection;
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};


export type PrivateIndividualPublicOfferPageTypePublicOfferAgreementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ProfessionalSkillType = {
  __typename?: 'ProfessionalSkillType';
  description: Scalars['String'];
  id: Scalars['ID'];
  studentCv: StudentCvType;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  description?: Maybe<Scalars['String']>;
  experience: WorkExperienceType;
  id: Scalars['ID'];
  projectName: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  technologies?: Maybe<Array<Scalars['String']>>;
};

export type ProjectsInput = {
  description?: InputMaybe<Scalars['String']>;
  projectName: Scalars['String'];
  summary?: InputMaybe<Scalars['String']>;
  technologies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PublishGroupToLms = {
  __typename?: 'PublishGroupToLMS';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  group?: Maybe<GroupType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  agreement?: Maybe<AgreementType>;
  allArticles?: Maybe<ArticlesConnection>;
  allCompanies?: Maybe<CompanyConnection>;
  allContributions?: Maybe<ContributionConnection>;
  allCountries?: Maybe<CountryConnection>;
  allCourseCategories?: Maybe<CategoryConnection>;
  allCourses?: Maybe<CourseFilterTypeConnection>;
  allCvLanguages?: Maybe<LanguageConnection>;
  allCvSocialMedias?: Maybe<SocialMediaConnection>;
  allDeferredPayments?: Maybe<DeferredPaymentConnection>;
  allGroups?: Maybe<GroupConnection>;
  allGroupsForLms?: Maybe<UnionGroupConnection>;
  allNatives?: Maybe<NativeInfoConnection>;
  allStories?: Maybe<AllStoriesConnection>;
  allStudents?: Maybe<StudentConnection>;
  allStudentsAchievement?: Maybe<StudentsAchievementConnection>;
  allSuccessStories?: Maybe<SuccessStoriesConnection>;
  allTeachers?: Maybe<TeacherFilterTypeConnection>;
  allTeachersApplications?: Maybe<ApplicationFilterTypeConnection>;
  allUsersSettings?: Maybe<UsersSettingsConnection>;
  article?: Maybe<ArticlesType>;
  articleBySlug?: Maybe<ArticlesType>;
  blogAllArticles?: Maybe<BlogAllArticlesType>;
  blogGraduateStory?: Maybe<BlogGraduateStoryType>;
  blogNative?: Maybe<BlogNativeType>;
  company?: Maybe<CompanyType>;
  contactsPage?: Maybe<ContactsPageType>;
  course?: Maybe<CourseType>;
  courseBySlug?: Maybe<CourseType>;
  courseCatalogPage?: Maybe<CourseCatalogPageType>;
  coursesGraduateFeedback?: Maybe<GraduateFeedbackConnection>;
  dataProcessingPolicyPage?: Maybe<DataProcessingPolicyType>;
  financeBudget?: Maybe<Array<Maybe<MonthBudget>>>;
  financeMonthBudget?: Maybe<WeeksBudgetInfo>;
  financePlan?: Maybe<Array<Maybe<MonthBudget>>>;
  financeYears?: Maybe<Array<Maybe<Scalars['Int']>>>;
  group?: Maybe<GroupType>;
  groupForLms?: Maybe<UnionGroupType>;
  groupLessonAttachment?: Maybe<LessonAttachmentsType>;
  groupLessonAttachmentForLms?: Maybe<Array<Maybe<LessonAttachmentsForLmsType>>>;
  groupLessonAttendanceForLms?: Maybe<Array<Maybe<LessonAttendancesType>>>;
  journal?: Maybe<GroupLessonConnection>;
  legalEntityPublicOfferPage?: Maybe<LegalEntityPublicOfferPageType>;
  lessonForLms?: Maybe<UnionLessonType>;
  lmsJournal?: Maybe<GroupLessonType>;
  mainPageInfo?: Maybe<MainPageInfoType>;
  mainTeachersWeb?: Maybe<MainTeacherWebType>;
  me?: Maybe<UserNode>;
  metaContributions?: Maybe<MetaContribution>;
  metaCourses?: Maybe<MetaCourses>;
  metaDeferredPayments?: Maybe<MetaDeferredPaymentConnection>;
  metaGroups?: Maybe<MetaGroups>;
  metaStudents?: Maybe<MetaStudents>;
  metaTeachers?: Maybe<MetaTeachers>;
  metaTeachersApplications?: Maybe<MetaTeacherApplications>;
  metaUsersSettings?: Maybe<MetaUsersSettings>;
  native?: Maybe<NativeInfoType>;
  nativeBySlug?: Maybe<NativeInfoType>;
  paymentRulesPage?: Maybe<PaymentRulesPageType>;
  paymentsVerification?: Maybe<Array<Maybe<PaymentsVerificationType>>>;
  privateIndividualPublicOfferPage?: Maybe<PrivateIndividualPublicOfferPageType>;
  robotTxt?: Maybe<RobotTxtType>;
  student?: Maybe<StudentType>;
  studentMarks?: Maybe<StudentsMarkConnection>;
  studentResume?: Maybe<StudentResumeType>;
  studentsCompaniesInfo?: Maybe<StudentAndCompaniesType>;
  studentsForJournal?: Maybe<StudentConnection>;
  studentsMarks?: Maybe<StudentConnection>;
  studentsReviews?: Maybe<StudentsReviewsType>;
  successStoriesBlock?: Maybe<SuccessStoriesBlockType>;
  successStory?: Maybe<SuccessStoriesType>;
  successStoryBySlug?: Maybe<SuccessStoriesType>;
  teacher?: Maybe<TeacherType>;
  teacherApplication?: Maybe<ApplicationType>;
  teachersPageInfo?: Maybe<TeachersPageInfoType>;
  /** The ID of the object */
  user?: Maybe<UserNode>;
  users?: Maybe<UserNodeConnection>;
  verifyStudent?: Maybe<VerifiedStudent>;
  verifyTeacher?: Maybe<VerifiedTeacher>;
};


export type QueryAgreementArgs = {
  agreementId: Scalars['ID'];
};


export type QueryAllArticlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAllContributionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contributionNum?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAllCountriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllCourseCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  category_Name?: InputMaybe<Scalars['String']>;
  educationForm?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderByPublishDateFilter?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  readyToPublish?: InputMaybe<Scalars['Boolean']>;
  startDateFilter?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAllCvLanguagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllCvSocialMediasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllDeferredPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAllGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAllGroupsForLmsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  finished?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllNativesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludedNatives?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllStoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  excludeArticles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludeStories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludedNatives?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  includeNatives?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllStudentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAllStudentsAchievementArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAllSuccessStoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAllTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  hasInterview?: InputMaybe<Scalars['Boolean']>;
  hasPhoto?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  siteDisplay?: InputMaybe<Scalars['Boolean']>;
};


export type QueryAllTeachersApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  byGroupId?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  groupsFilter?: InputMaybe<Scalars['Boolean']>;
  isTerminated?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryAllUsersSettingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryArticleArgs = {
  articleId?: InputMaybe<Scalars['ID']>;
};


export type QueryArticleBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCourseArgs = {
  courseId?: InputMaybe<Scalars['ID']>;
};


export type QueryCourseBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryCoursesGraduateFeedbackArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryFinanceBudgetArgs = {
  year: Scalars['Int'];
};


export type QueryFinanceMonthBudgetArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type QueryFinancePlanArgs = {
  year: Scalars['Int'];
};


export type QueryGroupArgs = {
  groupId?: InputMaybe<Scalars['ID']>;
};


export type QueryGroupForLmsArgs = {
  groupId?: InputMaybe<Scalars['ID']>;
};


export type QueryGroupLessonAttachmentArgs = {
  attachmentId?: InputMaybe<Scalars['ID']>;
  attachmentRole?: InputMaybe<Scalars['String']>;
};


export type QueryGroupLessonAttachmentForLmsArgs = {
  attachmentRole?: InputMaybe<Scalars['String']>;
  groupLessonId?: InputMaybe<Scalars['ID']>;
};


export type QueryGroupLessonAttendanceForLmsArgs = {
  groupLessonId?: InputMaybe<Scalars['ID']>;
};


export type QueryJournalArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  groupId: Scalars['ID'];
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryLessonForLmsArgs = {
  groupLessonId?: InputMaybe<Scalars['ID']>;
};


export type QueryLmsJournalArgs = {
  groupLessonId: Scalars['ID'];
};


export type QueryMetaContributionsArgs = {
  contributionNum?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryMetaCoursesArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryMetaDeferredPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryMetaGroupsArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryMetaStudentsArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryMetaTeachersArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryMetaTeachersApplicationsArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryMetaUsersSettingsArgs = {
  query?: InputMaybe<Scalars['String']>;
};


export type QueryNativeArgs = {
  nativeId?: InputMaybe<Scalars['ID']>;
};


export type QueryNativeBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryPaymentsVerificationArgs = {
  query: Array<InputMaybe<Scalars['String']>>;
};


export type QueryStudentArgs = {
  id: Scalars['ID'];
};


export type QueryStudentMarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lessonsId: Scalars['ID'];
};


export type QueryStudentResumeArgs = {
  language?: InputMaybe<Scalars['String']>;
};


export type QueryStudentsForJournalArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  groupId: Scalars['Int'];
  last?: InputMaybe<Scalars['Int']>;
  lessonsId: Array<InputMaybe<Scalars['Int']>>;
};


export type QueryStudentsMarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lessonsId: Scalars['ID'];
};


export type QuerySuccessStoryArgs = {
  storyId?: InputMaybe<Scalars['ID']>;
};


export type QuerySuccessStoryBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryTeacherArgs = {
  teacherId?: InputMaybe<Scalars['ID']>;
};


export type QueryTeacherApplicationArgs = {
  applicationId: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_Icontains?: InputMaybe<Scalars['String']>;
  email_Istartswith?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstName_Icontains?: InputMaybe<Scalars['String']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastName_Icontains?: InputMaybe<Scalars['String']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_Icontains?: InputMaybe<Scalars['String']>;
  phone_Istartswith?: InputMaybe<Scalars['String']>;
};


export type QueryVerifyStudentArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};


export type QueryVerifyTeacherArgs = {
  teacherInput: BaseTeacherInput;
};

export type RegisterMutation = {
  __typename?: 'RegisterMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RequirementsType = {
  __typename?: 'RequirementsType';
  courseRequirements: CourseRequirementsIntoType;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ResumeInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  cvLanguages?: InputMaybe<Array<InputMaybe<LanguagesInput>>>;
  education?: InputMaybe<Array<InputMaybe<EducationInput>>>;
  email?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Array<InputMaybe<WorkExperienceInput>>>;
  firstName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  language: LanguageEnum;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMedia?: InputMaybe<Array<InputMaybe<SocialMediaInput>>>;
  tools?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RevokeToken = {
  __typename?: 'RevokeToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  revoked?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RobotTxtType = {
  __typename?: 'RobotTxtType';
  id: Scalars['ID'];
  robotContent: Scalars['String'];
};

export type SalaryInfoType = {
  __typename?: 'SalaryInfoType';
  companyCount: Scalars['String'];
  course?: Maybe<CourseFilterType>;
  dataSource: Scalars['String'];
  id: Scalars['ID'];
  marketDemand: Scalars['String'];
  marketInfo: Scalars['String'];
  maxSeniorValue: Scalars['Int'];
  minJuniorValue: Scalars['Int'];
  minMiddleValue: Scalars['Int'];
  minSeniorValue: Scalars['Int'];
  title: Scalars['String'];
};

export type SelfEmployedInput = {
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  inn: Scalars['String'];
  nationality: Scalars['ID'];
  passportNumber: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type SelfEmployedType = Node & {
  __typename?: 'SelfEmployedType';
  accountNumber: Scalars['String'];
  bankTitle: Scalars['String'];
  bic: Scalars['String'];
  id: Scalars['ID'];
  inn: Scalars['String'];
  nationality: CountryType;
  passportNumber: Scalars['String'];
  registrationAddress: Scalars['String'];
};

export type SendAuthenticationCode = {
  __typename?: 'SendAuthenticationCode';
  createdAt?: Maybe<Scalars['DateTime']>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SetCompanyInput = {
  companyComment?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
};

export type SetStudentsMarksMutation = {
  __typename?: 'SetStudentsMarksMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  studentsMarks?: Maybe<StudentsMarkConnection>;
  success?: Maybe<Scalars['Boolean']>;
};


export type SetStudentsMarksMutationStudentsMarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type SkillsInCvType = {
  __typename?: 'SkillsInCVType';
  id: Scalars['ID'];
  skillName: Scalars['String'];
  student: StudentResumeType;
};

export type SocialMediaConnection = {
  __typename?: 'SocialMediaConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SocialMediaEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SocialMedia` and its cursor. */
export type SocialMediaEdge = {
  __typename?: 'SocialMediaEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SocialMediaType>;
};

export type SocialMediaInCvType = {
  __typename?: 'SocialMediaInCVType';
  id: Scalars['ID'];
  media?: Maybe<SocialMediaType>;
  socialLink: Scalars['String'];
  student: StudentResumeType;
};

export type SocialMediaInput = {
  media: Scalars['ID'];
  socialLink: Scalars['String'];
};

export type SocialMediaType = {
  __typename?: 'SocialMediaType';
  id: Scalars['ID'];
  mediaName: Scalars['String'];
  medias: Array<SocialMediaInCvType>;
};

export type StudentAndCompaniesType = {
  __typename?: 'StudentAndCompaniesType';
  companiesDescription: Scalars['String'];
  companyImages: Array<CompanyImageType>;
  id: Scalars['ID'];
  studentsCount: Scalars['Int'];
  studentsDescription: Scalars['String'];
};

/** An enumeration. */
export enum StudentCvLanguage {
  /** Eng */
  Eng = 'ENG',
  /** Rus */
  Rus = 'RUS'
}

export type StudentCvType = {
  __typename?: 'StudentCVType';
  certificateImage: Scalars['String'];
  course?: Maybe<CourseFilterType>;
  id: Scalars['ID'];
  professionalSkills: Array<ProfessionalSkillType>;
  salary: Scalars['String'];
  studentImage: Scalars['String'];
  title: Scalars['String'];
  workingPosition: Scalars['String'];
};

export type StudentConnection = {
  __typename?: 'StudentConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Student` and its cursor. */
export type StudentEdge = {
  __typename?: 'StudentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentType>;
};

export type StudentGroupType = Node & {
  __typename?: 'StudentGroupType';
  agreements: Array<AgreementType>;
  classesDays: Array<DayOfClassType>;
  comment?: Maybe<Scalars['String']>;
  contributionsSum?: Maybe<Scalars['CustomFloat']>;
  course: CourseFilterType;
  currency?: Maybe<Scalars['String']>;
  datetimeNextLesson?: Maybe<Scalars['DateTime']>;
  daysOfClass: Array<Scalars['Int']>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  freeLessonsCount?: Maybe<Scalars['Int']>;
  freePlaces?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lessons?: Maybe<Array<GroupLessonType>>;
  lessonsCount?: Maybe<Scalars['Int']>;
  maxStudentsCount: Scalars['Int'];
  modules: Array<GroupModuleType>;
  name: Scalars['String'];
  passedLessonsCount?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['CustomFloat']>;
  publishedLms: Scalars['Boolean'];
  publishedLmsForMethodist: Scalars['Boolean'];
  publishedLmsForTeacher: Scalars['Boolean'];
  startDate: Scalars['Date'];
  statusFinished?: Maybe<Scalars['Boolean']>;
  studentsCount?: Maybe<Scalars['Int']>;
  teacherLeads: ApplicationFilterTypeConnection;
  teachers?: Maybe<Array<Maybe<TeacherType>>>;
};


export type StudentGroupTypeTeacherLeadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  byGroupId?: InputMaybe<Scalars['Float']>;
  first?: InputMaybe<Scalars['Int']>;
  isTerminated?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum StudentHwTypeEnum {
  ContainsErrors = 'CONTAINS_ERRORS',
  Done = 'DONE',
  NotDone = 'NOT_DONE'
}

export type StudentInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type StudentLessonAttachmentType = Node & {
  __typename?: 'StudentLessonAttachmentType';
  file: Scalars['String'];
  id: Scalars['ID'];
  lesson?: Maybe<GroupLessonType>;
  title: Scalars['String'];
};

export type StudentLessonAttachmentTypeConnection = {
  __typename?: 'StudentLessonAttachmentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentLessonAttachmentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StudentLessonAttachmentType` and its cursor. */
export type StudentLessonAttachmentTypeEdge = {
  __typename?: 'StudentLessonAttachmentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentLessonAttachmentType>;
};

export type StudentLessonAttachmentsType = Node & {
  __typename?: 'StudentLessonAttachmentsType';
  courseLesson?: Maybe<CourseLessonType>;
  file: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type StudentLessonAttachmentsTypeConnection = {
  __typename?: 'StudentLessonAttachmentsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentLessonAttachmentsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StudentLessonAttachmentsType` and its cursor. */
export type StudentLessonAttachmentsTypeEdge = {
  __typename?: 'StudentLessonAttachmentsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentLessonAttachmentsType>;
};

export type StudentLessonType = Node & {
  __typename?: 'StudentLessonType';
  additional?: Maybe<Scalars['Boolean']>;
  attendances: AttendanceTypeConnection;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  endTime: Scalars['Time'];
  finished: Scalars['Boolean'];
  firstLessonAgreements: Array<AgreementType>;
  hasStudentAttachment?: Maybe<Scalars['Boolean']>;
  hasStudentMarks?: Maybe<Scalars['Boolean']>;
  hasTeacherAttachment?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  lastLessonAgreements: Array<AgreementType>;
  lessonNumber?: Maybe<Scalars['Int']>;
  lmsDescription?: Maybe<Scalars['String']>;
  marks: StudentsMarkTypeConnection;
  module: GroupModuleType;
  name: Scalars['String'];
  needToFinish?: Maybe<Scalars['Boolean']>;
  number: Scalars['Int'];
  startTime: Scalars['Time'];
  students: StudentTypeConnection;
  studentsAttachments: StudentLessonAttachmentTypeConnection;
  taskTypes: Array<Scalars['String']>;
  teacher?: Maybe<TeacherType>;
  teacherApplication?: Maybe<ApplicationType>;
  teachersAttachments: TeacherLessonAttachmentTypeConnection;
  video?: Maybe<Scalars['String']>;
};


export type StudentLessonTypeAttendancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type StudentLessonTypeMarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lessonId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  taskType?: InputMaybe<Scalars['String']>;
};


export type StudentLessonTypeStudentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type StudentLessonTypeStudentsAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type StudentLessonTypeTeachersAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type StudentResumeType = {
  __typename?: 'StudentResumeType';
  additionalInfo?: Maybe<Scalars['String']>;
  cvExperience: Array<WorkExperienceType>;
  cvLanguages: Array<LanguagesInCvType>;
  cvMedias: Array<SocialMediaInCvType>;
  educations: Array<EducationInCvType>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  language: StudentCvLanguage;
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  skills: Array<SkillsInCvType>;
  student: StudentType;
  tools: Array<ToolsInCvType>;
};

export type StudentType = Node & {
  __typename?: 'StudentType';
  accountsPayables?: Maybe<Array<Maybe<AccountsPayableType>>>;
  agreementIsActive?: Maybe<Scalars['Boolean']>;
  agreementTitle?: Maybe<AgreementType>;
  agreements: Array<AgreementType>;
  attendances: AttendanceTypeConnection;
  crmLink?: Maybe<Scalars['String']>;
  groupsCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lesson: GroupLessonTypeConnection;
  marks: StudentsMarkTypeConnection;
  resumes: Array<StudentResumeType>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<CustomUserType>;
};


export type StudentTypeAttendancesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type StudentTypeLessonArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
};


export type StudentTypeMarksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lessonId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  taskType?: InputMaybe<Scalars['String']>;
};

export type StudentTypeConnection = {
  __typename?: 'StudentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StudentType` and its cursor. */
export type StudentTypeEdge = {
  __typename?: 'StudentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentType>;
};

export type StudentWithAgreementCreateMutation = {
  __typename?: 'StudentWithAgreementCreateMutation';
  agreement?: Maybe<AgreementType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type StudentWithAgreementUpdateMutation = {
  __typename?: 'StudentWithAgreementUpdateMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  student?: Maybe<StudentType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type StudentsAchievementConnection = {
  __typename?: 'StudentsAchievementConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentsAchievementEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `StudentsAchievement` and its cursor. */
export type StudentsAchievementEdge = {
  __typename?: 'StudentsAchievementEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AgreementType>;
};

export type StudentsMarkConnection = {
  __typename?: 'StudentsMarkConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentsMarkEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `StudentsMark` and its cursor. */
export type StudentsMarkEdge = {
  __typename?: 'StudentsMarkEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentsMarkType>;
};

/** An enumeration. */
export enum StudentsMarkHwMark {
  /** Содержит ошибки */
  ContainsErrors = 'CONTAINS_ERRORS',
  /** Выполнено */
  Done = 'DONE',
  /** Не выполнено */
  NotDone = 'NOT_DONE'
}

/** An enumeration. */
export enum StudentsMarkTaskType {
  /** Курсовая работа */
  Coursework = 'COURSEWORK',
  /** Дипломный проект */
  GraduationProject = 'GRADUATION_PROJECT',
  /** Домашнее задание */
  Homework = 'HOMEWORK'
}

export type StudentsMarkType = Node & {
  __typename?: 'StudentsMarkType';
  date: Scalars['Date'];
  hwMark?: Maybe<StudentsMarkHwMark>;
  id: Scalars['ID'];
  lesson: GroupLessonType;
  /** От 0 до 10 */
  mark?: Maybe<Scalars['Int']>;
  student: StudentType;
  taskType?: Maybe<StudentsMarkTaskType>;
};

export type StudentsMarkTypeConnection = {
  __typename?: 'StudentsMarkTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StudentsMarkTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StudentsMarkType` and its cursor. */
export type StudentsMarkTypeEdge = {
  __typename?: 'StudentsMarkTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<StudentsMarkType>;
};

export type StudentsReviewsType = {
  __typename?: 'StudentsReviewsType';
  description: Scalars['String'];
  id: Scalars['ID'];
  markCountText: Scalars['String'];
  markText: Scalars['String'];
  marks: Scalars['Float'];
  marksCount: Scalars['Int'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};

export type SuccessStoriesBlockType = {
  __typename?: 'SuccessStoriesBlockType';
  id: Scalars['ID'];
  stories: Array<SuccessStoriesType>;
  title: Scalars['String'];
};

export type SuccessStoriesConnection = {
  __typename?: 'SuccessStoriesConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SuccessStoriesEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SuccessStories` and its cursor. */
export type SuccessStoriesEdge = {
  __typename?: 'SuccessStoriesEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SuccessStoriesType>;
};

export type SuccessStoriesType = {
  __typename?: 'SuccessStoriesType';
  adviceText?: Maybe<Scalars['String']>;
  adviceTitle?: Maybe<Scalars['String']>;
  author: AuthorsType;
  banner?: Maybe<CourseBanner>;
  date: Scalars['Date'];
  id: Scalars['ID'];
  interview?: Maybe<Scalars['String']>;
  interviewText?: Maybe<Scalars['String']>;
  native?: Maybe<NativeInfoType>;
  nativeTitle?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoSlug?: Maybe<Scalars['String']>;
  seoTitle: Scalars['String'];
  storyBlock: Array<SuccessStoriesBlockType>;
  storyImage?: Maybe<Scalars['String']>;
  storyShortText?: Maybe<Scalars['String']>;
  storyText?: Maybe<Scalars['String']>;
  storyTime?: Maybe<Scalars['String']>;
  studentImage?: Maybe<Scalars['String']>;
  studentName?: Maybe<Scalars['String']>;
  thesis: Array<ThesesType>;
  title: Scalars['String'];
};

export type TeacherFilterType = Node & {
  __typename?: 'TeacherFilterType';
  accessToLms: Scalars['Boolean'];
  applications?: Maybe<ApplicationConnection>;
  courses?: Maybe<Array<Maybe<Scalars['String']>>>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  employmentTypes?: Maybe<Array<Maybe<EmploymentType>>>;
  fullName?: Maybe<Scalars['String']>;
  groupsCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  individualentrepreneur?: Maybe<IndividualEntrepreneurType>;
  interview?: Maybe<Scalars['String']>;
  interviewTitle?: Maybe<Scalars['String']>;
  isDismissed?: Maybe<Scalars['Boolean']>;
  legalentity?: Maybe<LegalEntityType>;
  mainTeachers: MainTeacherWebTypeConnection;
  naturalperson?: Maybe<NaturalPersonType>;
  photo?: Maybe<Scalars['String']>;
  selfemployed?: Maybe<SelfEmployedType>;
  siteDisplay: Scalars['Boolean'];
  teacher: TeacherWebTypeConnection;
  user: CustomUserType;
};


export type TeacherFilterTypeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TeacherFilterTypeMainTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type TeacherFilterTypeTeacherArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TeacherFilterTypeConnection = {
  __typename?: 'TeacherFilterTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeacherFilterTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TeacherFilterType` and its cursor. */
export type TeacherFilterTypeEdge = {
  __typename?: 'TeacherFilterTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeacherType>;
};

export type TeacherLessonAttachmentType = Node & {
  __typename?: 'TeacherLessonAttachmentType';
  file: Scalars['String'];
  id: Scalars['ID'];
  lesson?: Maybe<GroupLessonType>;
  title: Scalars['String'];
};

export type TeacherLessonAttachmentTypeConnection = {
  __typename?: 'TeacherLessonAttachmentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeacherLessonAttachmentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TeacherLessonAttachmentType` and its cursor. */
export type TeacherLessonAttachmentTypeEdge = {
  __typename?: 'TeacherLessonAttachmentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeacherLessonAttachmentType>;
};

export type TeacherLessonAttachmentsType = Node & {
  __typename?: 'TeacherLessonAttachmentsType';
  courseLesson?: Maybe<CourseLessonType>;
  file: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type TeacherLessonAttachmentsTypeConnection = {
  __typename?: 'TeacherLessonAttachmentsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeacherLessonAttachmentsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TeacherLessonAttachmentsType` and its cursor. */
export type TeacherLessonAttachmentsTypeEdge = {
  __typename?: 'TeacherLessonAttachmentsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeacherLessonAttachmentsType>;
};

export type TeacherType = Node & {
  __typename?: 'TeacherType';
  accessToLms: Scalars['Boolean'];
  applications?: Maybe<ApplicationConnection>;
  courses?: Maybe<Array<Maybe<Scalars['String']>>>;
  deleteAvailable?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  employmentTypes?: Maybe<Array<Maybe<EmploymentType>>>;
  fullName?: Maybe<Scalars['String']>;
  groupsCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  interview?: Maybe<Scalars['String']>;
  interviewTitle?: Maybe<Scalars['String']>;
  isDismissed?: Maybe<Scalars['Boolean']>;
  mainTeachers: MainTeacherWebTypeConnection;
  photo?: Maybe<Scalars['String']>;
  siteDisplay: Scalars['Boolean'];
  teacher: TeacherWebTypeConnection;
  user: CustomUserType;
};


export type TeacherTypeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type TeacherTypeMainTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type TeacherTypeTeacherArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type TeacherWebType = Node & {
  __typename?: 'TeacherWebType';
  course?: Maybe<CourseFilterType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  teachersPreview: TeacherFilterTypeConnection;
  title: Scalars['String'];
};


export type TeacherWebTypeTeachersPreviewArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  hasInterview?: InputMaybe<Scalars['Boolean']>;
  hasPhoto?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  siteDisplay?: InputMaybe<Scalars['Boolean']>;
};

export type TeacherWebTypeConnection = {
  __typename?: 'TeacherWebTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TeacherWebTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TeacherWebType` and its cursor. */
export type TeacherWebTypeEdge = {
  __typename?: 'TeacherWebTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TeacherWebType>;
};

export type TeacherWithTypeInput = {
  accessToLms?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  siteDisplay?: InputMaybe<Scalars['Boolean']>;
};

export type TeachersPageInfoType = {
  __typename?: 'TeachersPageInfoType';
  description: Scalars['String'];
  id: Scalars['ID'];
  seoDescription: Scalars['String'];
  seoImage: Scalars['String'];
  seoKeywords: Scalars['String'];
  seoTitle: Scalars['String'];
  title: Scalars['String'];
};

export type ThesesType = {
  __typename?: 'ThesesType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  successStory?: Maybe<SuccessStoriesType>;
  title?: Maybe<Scalars['String']>;
};

export type ToolsInCvType = {
  __typename?: 'ToolsInCVType';
  id: Scalars['ID'];
  student: StudentResumeType;
  toolsName: Scalars['String'];
};

export type UnionGroupConnection = {
  __typename?: 'UnionGroupConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UnionGroupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UnionGroup` and its cursor. */
export type UnionGroupEdge = {
  __typename?: 'UnionGroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UnionGroupType>;
};

export type UnionGroupType = GroupType | MethodistGroupType | StudentGroupType;

export type UnionLessonType = GroupLessonForLmsType | MethodistLessonType | StudentLessonType;

export type UpdateApplicationInputType = {
  applicationId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['ID']>;
  currency: Currencies;
  date: Scalars['Date'];
  group: Scalars['ID'];
  isTerminated: Scalars['Boolean'];
  lastLessonDate?: InputMaybe<Scalars['Date']>;
  lessonsCount: Scalars['Int'];
  riaPrice?: InputMaybe<Scalars['Float']>;
  servicePrice: Scalars['Float'];
  teacherType: EmploymentTypes;
  title: Scalars['String'];
};

export type UpdateAttendanceMutation = {
  __typename?: 'UpdateAttendanceMutation';
  attendance?: Maybe<AttendanceType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCompanyInput = {
  comment: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  responsiblePerson: Scalars['String'];
};

export type UpdateCompanyMutation = {
  __typename?: 'UpdateCompanyMutation';
  company?: Maybe<CompanyType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCourseMutation = {
  __typename?: 'UpdateCourseMutation';
  course?: Maybe<CourseType>;
};

export type UpdateGroupInput = {
  course_id: Scalars['Int'];
  currency: Scalars['String'];
  daysOfClass: Array<DayOfClassInput>;
  modules: Array<InputMaybe<UpdateModuleInput>>;
  name: Scalars['String'];
  price: Scalars['Float'];
  startDate: Scalars['Date'];
};

export type UpdateGroupMutation = {
  __typename?: 'UpdateGroupMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  group?: Maybe<GroupType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateJournalsMutation = {
  __typename?: 'UpdateJournalsMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateLessonInput = {
  additional?: InputMaybe<Scalars['Boolean']>;
  application_id?: InputMaybe<Scalars['ID']>;
  lesson_id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  teacher_id?: InputMaybe<Scalars['Int']>;
};

export type UpdateModuleInput = {
  lessons: Array<InputMaybe<UpdateLessonInput>>;
  module_id: Scalars['Int'];
};

export type UpdateStudentInput = {
  accountsPayable?: InputMaybe<AccountsPayableInput>;
  agreement: AgreementInput;
  chargeback?: InputMaybe<ChargebackInput>;
  company?: InputMaybe<SetCompanyInput>;
  contributions?: InputMaybe<Array<InputMaybe<ContributionInput>>>;
  student: StudentInput;
  studentLessons?: InputMaybe<AgreementLessonsInput>;
  usedPayables?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateTeacherApplication = {
  __typename?: 'UpdateTeacherApplication';
  application?: Maybe<ApplicationType>;
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateTeacherWithTypeMutation = {
  __typename?: 'UpdateTeacherWithTypeMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
  teacher?: Maybe<TeacherType>;
};

export type UploadLessonVideo = {
  __typename?: 'UploadLessonVideo';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UploadResume = {
  __typename?: 'UploadResume';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  resume?: Maybe<StudentResumeType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  activeRole?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  methodistGroupsCount?: Maybe<Scalars['Int']>;
  middleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['Int']>;
  roles: Array<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  student?: Maybe<StudentType>;
  teacher?: Maybe<TeacherFilterType>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  verified?: Maybe<Scalars['Boolean']>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

export type UserSettingsDeleteMutation = {
  __typename?: 'UserSettingsDeleteMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UserSettingsRolesAddMutation = {
  __typename?: 'UserSettingsRolesAddMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<CustomUserType>;
};

export type UserSettingsUpdateMutation = {
  __typename?: 'UserSettingsUpdateMutation';
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserNode>;
};

export type UsersSettingsConnection = {
  __typename?: 'UsersSettingsConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UsersSettingsEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UsersSettings` and its cursor. */
export type UsersSettingsEdge = {
  __typename?: 'UsersSettingsEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

export type VerifiedStudent = {
  __typename?: 'VerifiedStudent';
  code: Scalars['String'];
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  message?: Maybe<Scalars['String']>;
  student?: Maybe<StudentType>;
  user?: Maybe<CustomUserType>;
};

export type VerifiedTeacher = {
  __typename?: 'VerifiedTeacher';
  code: Scalars['String'];
  customErrors?: Maybe<Array<Maybe<CustomErrorType>>>;
  message?: Maybe<Scalars['String']>;
  teacher?: Maybe<TeacherType>;
  user?: Maybe<CustomUserType>;
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type VerifyToken = {
  __typename?: 'VerifyToken';
  errors?: Maybe<Scalars['ExpectedErrorType']>;
  payload?: Maybe<Scalars['GenericScalar']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type VideoTrailerType = {
  __typename?: 'VideoTrailerType';
  course?: Maybe<CourseFilterType>;
  description: Scalars['String'];
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type WeeksBudget = {
  __typename?: 'WeeksBudget';
  BYN?: Maybe<Scalars['CustomFloat']>;
  EUR?: Maybe<Scalars['CustomFloat']>;
  RUB?: Maybe<Scalars['CustomFloat']>;
  USD?: Maybe<Scalars['CustomFloat']>;
  week?: Maybe<Scalars['Int']>;
};

export type WeeksBudgetInfo = {
  __typename?: 'WeeksBudgetInfo';
  budget?: Maybe<Array<Maybe<WeeksBudget>>>;
  plan?: Maybe<Array<Maybe<WeeksBudget>>>;
};

export type WorkExperienceInput = {
  company?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<CvDateInput>;
  jobTitle?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Array<InputMaybe<ProjectsInput>>>;
  startDate?: InputMaybe<CvDateInput>;
};

export type WorkExperienceType = {
  __typename?: 'WorkExperienceType';
  company?: Maybe<Scalars['String']>;
  endDateMonth?: Maybe<Scalars['String']>;
  endDateYear?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  projects: Array<ProjectType>;
  startDateMonth?: Maybe<Scalars['String']>;
  startDateYear?: Maybe<Scalars['Int']>;
  studentCv: StudentResumeType;
};

export type UserFieldsFragment = { __typename?: 'UserNode', id: string, activeRole?: string | null, username: string, firstName: string, middleName?: string | null, lastName: string, email: string, roles: Array<string>, phone?: string | null, methodistGroupsCount?: number | null, student?: { __typename?: 'StudentType', groupsCount?: number | null } | null, teacher?: { __typename?: 'TeacherFilterType', photo?: string | null, groupsCount?: number | null } | null };

export type SendAuthCodeMutationVariables = Exact<{
  email: Scalars['String'];
  scope: Scalars['String'];
}>;


export type SendAuthCodeMutation = { __typename?: 'Mutation', sendLmsAuthEmail?: { __typename?: 'SendAuthenticationCode', success?: boolean | null, createdAt?: any | null, customErrors?: Array<{ __typename?: 'CustomErrorType', code?: string | null, field?: string | null, message?: string | null } | null> | null } | null };

export type LoginMutationVariables = Exact<{
  authenticationCode: Scalars['String'];
  email: Scalars['String'];
  scope: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', customLogin?: { __typename?: 'CustomObtainJSONWebToken', success?: boolean | null, token?: string | null, refreshToken?: string | null, customErrors?: Array<{ __typename?: 'CustomErrorType', code?: string | null, field?: string | null, message?: string | null } | null> | null, user?: { __typename?: 'UserNode', id: string, activeRole?: string | null, username: string, firstName: string, middleName?: string | null, lastName: string, email: string, roles: Array<string>, phone?: string | null, methodistGroupsCount?: number | null, student?: { __typename?: 'StudentType', groupsCount?: number | null } | null, teacher?: { __typename?: 'TeacherFilterType', photo?: string | null, groupsCount?: number | null } | null } | null } | null };

export type RefreshTokenMutationVariables = Exact<{
  refresh_token: Scalars['String'];
  scope: Scalars['String'];
  activeRole?: InputMaybe<LmsRoles>;
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'CustomRefreshToken', token?: string | null, refreshToken?: string | null, payload?: any | null, errors?: any | null } | null };

export type FinishLessonMutationVariables = Exact<{
  groupLessonId: Scalars['Int'];
  comment: Scalars['String'];
  attendances: Array<LmsAttendanceType> | LmsAttendanceType;
}>;


export type FinishLessonMutation = { __typename?: 'Mutation', finishLmsLesson?: { __typename?: 'FinishLmsLessonMutation', success?: boolean | null, errors?: any | null, customErrors?: Array<{ __typename?: 'CustomErrorType', code?: string | null, field?: string | null, message?: string | null } | null> | null } | null };

export type UploadLessonVideoMutationVariables = Exact<{
  lessonId: Scalars['Int'];
  videoUrl: Scalars['String'];
}>;


export type UploadLessonVideoMutation = { __typename?: 'Mutation', uploadLessonVideo?: { __typename?: 'UploadLessonVideo', success?: boolean | null, customErrors?: Array<{ __typename?: 'CustomErrorType', code?: string | null, field?: string | null, message?: string | null } | null> | null } | null };

export type CancelUploadVideoMutationVariables = Exact<{
  lessonId: Scalars['Int'];
}>;


export type CancelUploadVideoMutation = { __typename?: 'Mutation', cancelVideoUpload?: { __typename?: 'CancelVideoUpload', customErrors?: Array<{ __typename?: 'CustomErrorType', field?: string | null, code?: string | null, message?: string | null } | null> | null } | null };

export type AddStudentsMarksMutationVariables = Exact<{
  lessonId: Scalars['Int'];
  taskType: LessonTaskTypeEnum;
  studentsMarks: Array<InputMaybe<LessonMarkInput>> | InputMaybe<LessonMarkInput>;
  date: Scalars['Date'];
}>;


export type AddStudentsMarksMutation = { __typename?: 'Mutation', setStudentsMarks?: { __typename?: 'SetStudentsMarksMutation', success?: boolean | null, customErrors?: Array<{ __typename?: 'CustomErrorType', field?: string | null, code?: string | null, message?: string | null } | null> | null } | null };

export type UploadResumeMutationVariables = Exact<{
  photo?: InputMaybe<Scalars['Upload']>;
  resumeInput: ResumeInput;
}>;


export type UploadResumeMutation = { __typename?: 'Mutation', uploadResume?: { __typename?: 'UploadResume', success?: boolean | null, customErrors?: Array<{ __typename?: 'CustomErrorType', code?: string | null, field?: string | null, message?: string | null } | null> | null, resume?: { __typename?: 'StudentResumeType', id: string } | null } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UserNode', id: string, activeRole?: string | null, username: string, firstName: string, middleName?: string | null, lastName: string, email: string, roles: Array<string>, phone?: string | null, methodistGroupsCount?: number | null, student?: { __typename?: 'StudentType', groupsCount?: number | null } | null, teacher?: { __typename?: 'TeacherFilterType', photo?: string | null, groupsCount?: number | null } | null } | null };

export type AllCoursesQueryVariables = Exact<{
  active?: InputMaybe<Scalars['Boolean']>;
  finished?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type AllCoursesQuery = { __typename?: 'Query', allGroupsForLms?: { __typename?: 'UnionGroupConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ __typename?: 'UnionGroupEdge', cursor: string, node?: { __typename?: 'GroupType', id: string, passedLessonsCount?: number | null, lessonsCount?: number | null, studentsCount?: number | null, name: string, statusFinished?: boolean | null, datetimeNextLesson?: any | null, course: { __typename?: 'CourseFilterType', id: string, courseIcon?: string | null, educationForm?: string | null, name: string } } | { __typename?: 'MethodistGroupType', id: string, passedLessonsCount?: number | null, lessonsCount?: number | null, studentsCount?: number | null, name: string, statusFinished?: boolean | null, datetimeNextLesson?: any | null, course: { __typename?: 'CourseFilterType', id: string, courseIcon?: string | null, educationForm?: string | null, name: string } } | { __typename?: 'StudentGroupType', id: string, passedLessonsCount?: number | null, lessonsCount?: number | null, studentsCount?: number | null, name: string, statusFinished?: boolean | null, datetimeNextLesson?: any | null, course: { __typename?: 'CourseFilterType', id: string, courseIcon?: string | null, educationForm?: string | null, name: string } } | null } | null> } | null };

export type GroupForLmsQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars['ID']>;
}>;


export type GroupForLmsQuery = { __typename?: 'Query', groupForLms?: { __typename?: 'GroupType', id: string, statusFinished?: boolean | null, passedLessonsCount?: number | null, lessonsCount?: number | null, studentsCount?: number | null, name: string, course: { __typename?: 'CourseFilterType', id: string, courseIcon?: string | null, name: string }, lessons?: Array<{ __typename?: 'GroupLessonType', id: string, name: string, date: any, index?: number | null, needToFinish?: boolean | null }> | null } | { __typename?: 'MethodistGroupType', id: string, statusFinished?: boolean | null, passedLessonsCount?: number | null, lessonsCount?: number | null, studentsCount?: number | null, name: string, course: { __typename?: 'CourseFilterType', id: string, courseIcon?: string | null, name: string }, lessons?: Array<{ __typename?: 'GroupLessonType', id: string, name: string, date: any, index?: number | null }> | null } | { __typename?: 'StudentGroupType', id: string, statusFinished?: boolean | null, passedLessonsCount?: number | null, lessonsCount?: number | null, studentsCount?: number | null, name: string, course: { __typename?: 'CourseFilterType', id: string, courseIcon?: string | null, name: string }, lessons?: Array<{ __typename?: 'GroupLessonType', id: string, name: string, date: any, index?: number | null }> | null } | null };

export type LessonForLmsQueryVariables = Exact<{
  groupLessonId?: InputMaybe<Scalars['ID']>;
}>;


export type LessonForLmsQuery = { __typename?: 'Query', lessonForLms?: { __typename?: 'GroupLessonForLMSType', id: string, name: string, date: any, index?: number | null, lmsDescription?: string | null, startTime: any, endTime: any, finished: boolean, needToFinish?: boolean | null, comment?: string | null, video?: string | null, taskTypes: Array<string>, hasTeacherAttachment?: boolean | null } | { __typename?: 'MethodistLessonType', id: string, name: string, date: any, index?: number | null, lmsDescription?: string | null, startTime: any, endTime: any, finished: boolean, comment?: string | null, video?: string | null, hasTeacherAttachment?: boolean | null, hasStudentAttachment?: boolean | null } | { __typename?: 'StudentLessonType', id: string, name: string, date: any, index?: number | null, lmsDescription?: string | null, startTime: any, endTime: any, finished: boolean, comment?: string | null, video?: string | null, hasStudentMarks?: boolean | null, hasStudentAttachment?: boolean | null } | null };

export type GroupLessonAttendanceForLmsQueryVariables = Exact<{
  groupLessonId?: InputMaybe<Scalars['ID']>;
}>;


export type GroupLessonAttendanceForLmsQuery = { __typename?: 'Query', groupLessonAttendanceForLms?: Array<{ __typename?: 'LessonAttendancesType', id: string, attendance?: boolean | null, student: { __typename?: 'StudentType', id: string, user?: { __typename?: 'CustomUserType', id: string, firstName: string, lastName: string } | null } } | null> | null };

export type GroupLessonAttachmentForLmsQueryVariables = Exact<{
  groupLessonId?: InputMaybe<Scalars['ID']>;
  attachmentRole?: InputMaybe<Scalars['String']>;
}>;


export type GroupLessonAttachmentForLmsQuery = { __typename?: 'Query', groupLessonAttachmentForLms?: Array<{ __typename?: 'LessonAttachmentsForLMSType', id: string, title: string, file: string } | null> | null };

export type GroupLessonAttachmentQueryVariables = Exact<{
  attachmentId?: InputMaybe<Scalars['ID']>;
  attachmentRole?: InputMaybe<Scalars['String']>;
}>;


export type GroupLessonAttachmentQuery = { __typename?: 'Query', groupLessonAttachment?: { __typename?: 'LessonAttachmentsType', id?: string | null, title?: string | null, file?: string | null } | null };

export type StudentsMarksQueryVariables = Exact<{
  lessonsId: Scalars['ID'];
  lessonIdForMarks: Scalars['String'];
  taskType: Scalars['String'];
}>;


export type StudentsMarksQuery = { __typename?: 'Query', studentsMarks?: { __typename?: 'StudentConnection', edges: Array<{ __typename?: 'StudentEdge', node?: { __typename?: 'StudentType', id: string, user?: { __typename?: 'CustomUserType', id: string, firstName: string, lastName: string, student?: { __typename?: 'StudentType', id: string } | null } | null, marks: { __typename?: 'StudentsMarkTypeConnection', edges: Array<{ __typename?: 'StudentsMarkTypeEdge', node?: { __typename?: 'StudentsMarkType', id: string, date: any, mark?: number | null, hwMark?: StudentsMarkHwMark | null } | null } | null> } } | null } | null> } | null };

export type StudentMarksQueryVariables = Exact<{
  lessonsId: Scalars['ID'];
}>;


export type StudentMarksQuery = { __typename?: 'Query', studentMarks?: { __typename?: 'StudentsMarkConnection', edges: Array<{ __typename?: 'StudentsMarkEdge', node?: { __typename?: 'StudentsMarkType', mark?: number | null, hwMark?: StudentsMarkHwMark | null, date: any, taskType?: StudentsMarkTaskType | null } | null } | null> } | null };

export type StudentResumeQueryVariables = Exact<{
  language?: InputMaybe<Scalars['String']>;
}>;


export type StudentResumeQuery = { __typename?: 'Query', studentResume?: { __typename?: 'StudentResumeType', id: string, firstName: string, lastName: string, email?: string | null, phone?: string | null, jobTitle?: string | null, additionalInfo?: string | null, image?: string | null, language: StudentCvLanguage, tools: Array<{ __typename?: 'ToolsInCVType', toolsName: string }>, skills: Array<{ __typename?: 'SkillsInCVType', skillName: string }>, educations: Array<{ __typename?: 'EducationInCVType', id: string, edInstitution?: string | null, degree?: string | null, specialization?: string | null, startDateYear?: number | null, startDateMonth?: string | null, endDateYear?: number | null, endDateMonth?: string | null }>, cvMedias: Array<{ __typename?: 'SocialMediaInCVType', id: string, socialLink: string, media?: { __typename?: 'SocialMediaType', mediaName: string } | null }>, cvExperience: Array<{ __typename?: 'WorkExperienceType', id: string, company?: string | null, jobTitle?: string | null, startDateYear?: number | null, startDateMonth?: string | null, endDateYear?: number | null, endDateMonth?: string | null, projects: Array<{ __typename?: 'ProjectType', id: string, projectName: string, description?: string | null, summary?: string | null, technologies?: Array<string> | null }> }>, cvLanguages: Array<{ __typename?: 'LanguagesInCVType', id: string, languageLevel: LanguagesInCvLanguageLevel, language: { __typename?: 'LanguageType', language: string } }> } | null };

export type ResumeSocialMediaQueryVariables = Exact<{ [key: string]: never; }>;


export type ResumeSocialMediaQuery = { __typename?: 'Query', allCvSocialMedias?: { __typename?: 'SocialMediaConnection', edges: Array<{ __typename?: 'SocialMediaEdge', node?: { __typename?: 'SocialMediaType', id: string, mediaName: string } | null } | null> } | null };

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on UserNode {
  id
  activeRole
  username
  firstName
  middleName
  lastName
  email
  roles
  phone
  methodistGroupsCount
  student {
    groupsCount
  }
  teacher {
    photo
    groupsCount
  }
}
    `;
export const SendAuthCodeDocument = gql`
    mutation sendAuthCode($email: String!, $scope: String!) {
  sendLmsAuthEmail(authenticationInput: {email: $email, scope: $scope}) {
    success
    createdAt
    customErrors {
      code
      field
      message
    }
  }
}
    `;
export type SendAuthCodeMutationFn = Apollo.MutationFunction<SendAuthCodeMutation, SendAuthCodeMutationVariables>;

/**
 * __useSendAuthCodeMutation__
 *
 * To run a mutation, you first call `useSendAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAuthCodeMutation, { data, loading, error }] = useSendAuthCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useSendAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendAuthCodeMutation, SendAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAuthCodeMutation, SendAuthCodeMutationVariables>(SendAuthCodeDocument, options);
      }
export type SendAuthCodeMutationHookResult = ReturnType<typeof useSendAuthCodeMutation>;
export type SendAuthCodeMutationResult = Apollo.MutationResult<SendAuthCodeMutation>;
export type SendAuthCodeMutationOptions = Apollo.BaseMutationOptions<SendAuthCodeMutation, SendAuthCodeMutationVariables>;
export const LoginDocument = gql`
    mutation login($authenticationCode: String!, $email: String!, $scope: String!) {
  customLogin(
    authenticationCode: $authenticationCode
    email: $email
    scope: $scope
  ) {
    success
    token
    refreshToken
    customErrors {
      code
      field
      message
    }
    user {
      ...UserFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      authenticationCode: // value for 'authenticationCode'
 *      email: // value for 'email'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($refresh_token: String!, $scope: String!, $activeRole: LmsRoles) {
  refreshToken(
    refreshToken: $refresh_token
    scope: $scope
    activeRole: $activeRole
  ) {
    token
    refreshToken
    payload
    errors
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *      scope: // value for 'scope'
 *      activeRole: // value for 'activeRole'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const FinishLessonDocument = gql`
    mutation finishLesson($groupLessonId: Int!, $comment: String!, $attendances: [LmsAttendanceType!]!) {
  finishLmsLesson(
    groupLessonId: $groupLessonId
    comment: $comment
    attendances: $attendances
  ) {
    success
    errors
    customErrors {
      code
      field
      message
    }
  }
}
    `;
export type FinishLessonMutationFn = Apollo.MutationFunction<FinishLessonMutation, FinishLessonMutationVariables>;

/**
 * __useFinishLessonMutation__
 *
 * To run a mutation, you first call `useFinishLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishLessonMutation, { data, loading, error }] = useFinishLessonMutation({
 *   variables: {
 *      groupLessonId: // value for 'groupLessonId'
 *      comment: // value for 'comment'
 *      attendances: // value for 'attendances'
 *   },
 * });
 */
export function useFinishLessonMutation(baseOptions?: Apollo.MutationHookOptions<FinishLessonMutation, FinishLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishLessonMutation, FinishLessonMutationVariables>(FinishLessonDocument, options);
      }
export type FinishLessonMutationHookResult = ReturnType<typeof useFinishLessonMutation>;
export type FinishLessonMutationResult = Apollo.MutationResult<FinishLessonMutation>;
export type FinishLessonMutationOptions = Apollo.BaseMutationOptions<FinishLessonMutation, FinishLessonMutationVariables>;
export const UploadLessonVideoDocument = gql`
    mutation uploadLessonVideo($lessonId: Int!, $videoUrl: String!) {
  uploadLessonVideo(lessonId: $lessonId, videoUrl: $videoUrl) {
    success
    customErrors {
      code
      field
      message
    }
  }
}
    `;
export type UploadLessonVideoMutationFn = Apollo.MutationFunction<UploadLessonVideoMutation, UploadLessonVideoMutationVariables>;

/**
 * __useUploadLessonVideoMutation__
 *
 * To run a mutation, you first call `useUploadLessonVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadLessonVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadLessonVideoMutation, { data, loading, error }] = useUploadLessonVideoMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      videoUrl: // value for 'videoUrl'
 *   },
 * });
 */
export function useUploadLessonVideoMutation(baseOptions?: Apollo.MutationHookOptions<UploadLessonVideoMutation, UploadLessonVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadLessonVideoMutation, UploadLessonVideoMutationVariables>(UploadLessonVideoDocument, options);
      }
export type UploadLessonVideoMutationHookResult = ReturnType<typeof useUploadLessonVideoMutation>;
export type UploadLessonVideoMutationResult = Apollo.MutationResult<UploadLessonVideoMutation>;
export type UploadLessonVideoMutationOptions = Apollo.BaseMutationOptions<UploadLessonVideoMutation, UploadLessonVideoMutationVariables>;
export const CancelUploadVideoDocument = gql`
    mutation cancelUploadVideo($lessonId: Int!) {
  cancelVideoUpload(lessonId: $lessonId) {
    customErrors {
      field
      code
      message
    }
  }
}
    `;
export type CancelUploadVideoMutationFn = Apollo.MutationFunction<CancelUploadVideoMutation, CancelUploadVideoMutationVariables>;

/**
 * __useCancelUploadVideoMutation__
 *
 * To run a mutation, you first call `useCancelUploadVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUploadVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUploadVideoMutation, { data, loading, error }] = useCancelUploadVideoMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useCancelUploadVideoMutation(baseOptions?: Apollo.MutationHookOptions<CancelUploadVideoMutation, CancelUploadVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelUploadVideoMutation, CancelUploadVideoMutationVariables>(CancelUploadVideoDocument, options);
      }
export type CancelUploadVideoMutationHookResult = ReturnType<typeof useCancelUploadVideoMutation>;
export type CancelUploadVideoMutationResult = Apollo.MutationResult<CancelUploadVideoMutation>;
export type CancelUploadVideoMutationOptions = Apollo.BaseMutationOptions<CancelUploadVideoMutation, CancelUploadVideoMutationVariables>;
export const AddStudentsMarksDocument = gql`
    mutation addStudentsMarks($lessonId: Int!, $taskType: LessonTaskTypeEnum!, $studentsMarks: [LessonMarkInput]!, $date: Date!) {
  setStudentsMarks(
    lessonId: $lessonId
    taskType: $taskType
    studentsMarks: $studentsMarks
    date: $date
  ) {
    success
    customErrors {
      field
      code
      message
    }
  }
}
    `;
export type AddStudentsMarksMutationFn = Apollo.MutationFunction<AddStudentsMarksMutation, AddStudentsMarksMutationVariables>;

/**
 * __useAddStudentsMarksMutation__
 *
 * To run a mutation, you first call `useAddStudentsMarksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStudentsMarksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStudentsMarksMutation, { data, loading, error }] = useAddStudentsMarksMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      taskType: // value for 'taskType'
 *      studentsMarks: // value for 'studentsMarks'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAddStudentsMarksMutation(baseOptions?: Apollo.MutationHookOptions<AddStudentsMarksMutation, AddStudentsMarksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStudentsMarksMutation, AddStudentsMarksMutationVariables>(AddStudentsMarksDocument, options);
      }
export type AddStudentsMarksMutationHookResult = ReturnType<typeof useAddStudentsMarksMutation>;
export type AddStudentsMarksMutationResult = Apollo.MutationResult<AddStudentsMarksMutation>;
export type AddStudentsMarksMutationOptions = Apollo.BaseMutationOptions<AddStudentsMarksMutation, AddStudentsMarksMutationVariables>;
export const UploadResumeDocument = gql`
    mutation uploadResume($photo: Upload, $resumeInput: ResumeInput!) {
  uploadResume(photo: $photo, resumeInput: $resumeInput) {
    success
    customErrors {
      code
      field
      message
    }
    resume {
      id
    }
  }
}
    `;
export type UploadResumeMutationFn = Apollo.MutationFunction<UploadResumeMutation, UploadResumeMutationVariables>;

/**
 * __useUploadResumeMutation__
 *
 * To run a mutation, you first call `useUploadResumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadResumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadResumeMutation, { data, loading, error }] = useUploadResumeMutation({
 *   variables: {
 *      photo: // value for 'photo'
 *      resumeInput: // value for 'resumeInput'
 *   },
 * });
 */
export function useUploadResumeMutation(baseOptions?: Apollo.MutationHookOptions<UploadResumeMutation, UploadResumeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadResumeMutation, UploadResumeMutationVariables>(UploadResumeDocument, options);
      }
export type UploadResumeMutationHookResult = ReturnType<typeof useUploadResumeMutation>;
export type UploadResumeMutationResult = Apollo.MutationResult<UploadResumeMutation>;
export type UploadResumeMutationOptions = Apollo.BaseMutationOptions<UploadResumeMutation, UploadResumeMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AllCoursesDocument = gql`
    query AllCourses($active: Boolean, $finished: Boolean, $first: Int, $after: String, $last: Int) {
  allGroupsForLms(
    active: $active
    finished: $finished
    first: $first
    after: $after
    last: $last
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ... on GroupType {
          id
          course {
            id
            courseIcon
            educationForm
            name
          }
          passedLessonsCount
          lessonsCount
          studentsCount
          name
          statusFinished
          datetimeNextLesson
        }
        ... on StudentGroupType {
          id
          course {
            id
            courseIcon
            educationForm
            name
          }
          passedLessonsCount
          lessonsCount
          studentsCount
          name
          statusFinished
          datetimeNextLesson
        }
        ... on MethodistGroupType {
          id
          course {
            id
            courseIcon
            educationForm
            name
          }
          passedLessonsCount
          lessonsCount
          studentsCount
          name
          statusFinished
          datetimeNextLesson
        }
      }
    }
  }
}
    `;

/**
 * __useAllCoursesQuery__
 *
 * To run a query within a React component, call `useAllCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCoursesQuery({
 *   variables: {
 *      active: // value for 'active'
 *      finished: // value for 'finished'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAllCoursesQuery(baseOptions?: Apollo.QueryHookOptions<AllCoursesQuery, AllCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCoursesQuery, AllCoursesQueryVariables>(AllCoursesDocument, options);
      }
export function useAllCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCoursesQuery, AllCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCoursesQuery, AllCoursesQueryVariables>(AllCoursesDocument, options);
        }
export type AllCoursesQueryHookResult = ReturnType<typeof useAllCoursesQuery>;
export type AllCoursesLazyQueryHookResult = ReturnType<typeof useAllCoursesLazyQuery>;
export type AllCoursesQueryResult = Apollo.QueryResult<AllCoursesQuery, AllCoursesQueryVariables>;
export const GroupForLmsDocument = gql`
    query groupForLms($groupId: ID) {
  groupForLms(groupId: $groupId) {
    ... on GroupType {
      id
      course {
        id
        courseIcon
        name
      }
      statusFinished
      lessons {
        id
        name
        date
        index
        needToFinish
      }
      passedLessonsCount
      lessonsCount
      studentsCount
      name
    }
    ... on StudentGroupType {
      id
      course {
        id
        courseIcon
        name
      }
      statusFinished
      lessons {
        id
        name
        date
        index
      }
      passedLessonsCount
      lessonsCount
      studentsCount
      name
    }
    ... on MethodistGroupType {
      id
      course {
        id
        courseIcon
        name
      }
      statusFinished
      lessons {
        id
        name
        date
        index
      }
      passedLessonsCount
      lessonsCount
      studentsCount
      name
    }
  }
}
    `;

/**
 * __useGroupForLmsQuery__
 *
 * To run a query within a React component, call `useGroupForLmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupForLmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupForLmsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupForLmsQuery(baseOptions?: Apollo.QueryHookOptions<GroupForLmsQuery, GroupForLmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupForLmsQuery, GroupForLmsQueryVariables>(GroupForLmsDocument, options);
      }
export function useGroupForLmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupForLmsQuery, GroupForLmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupForLmsQuery, GroupForLmsQueryVariables>(GroupForLmsDocument, options);
        }
export type GroupForLmsQueryHookResult = ReturnType<typeof useGroupForLmsQuery>;
export type GroupForLmsLazyQueryHookResult = ReturnType<typeof useGroupForLmsLazyQuery>;
export type GroupForLmsQueryResult = Apollo.QueryResult<GroupForLmsQuery, GroupForLmsQueryVariables>;
export const LessonForLmsDocument = gql`
    query lessonForLms($groupLessonId: ID) {
  lessonForLms(groupLessonId: $groupLessonId) {
    ... on GroupLessonForLMSType {
      id
      name
      date
      index
      lmsDescription
      startTime
      endTime
      finished
      needToFinish
      comment
      video
      taskTypes
      hasTeacherAttachment
    }
    ... on StudentLessonType {
      id
      name
      date
      index
      lmsDescription
      startTime
      endTime
      finished
      comment
      video
      hasStudentMarks
      hasStudentAttachment
    }
    ... on MethodistLessonType {
      id
      name
      date
      index
      lmsDescription
      startTime
      endTime
      finished
      comment
      video
      hasTeacherAttachment
      hasStudentAttachment
    }
  }
}
    `;

/**
 * __useLessonForLmsQuery__
 *
 * To run a query within a React component, call `useLessonForLmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLessonForLmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLessonForLmsQuery({
 *   variables: {
 *      groupLessonId: // value for 'groupLessonId'
 *   },
 * });
 */
export function useLessonForLmsQuery(baseOptions?: Apollo.QueryHookOptions<LessonForLmsQuery, LessonForLmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LessonForLmsQuery, LessonForLmsQueryVariables>(LessonForLmsDocument, options);
      }
export function useLessonForLmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LessonForLmsQuery, LessonForLmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LessonForLmsQuery, LessonForLmsQueryVariables>(LessonForLmsDocument, options);
        }
export type LessonForLmsQueryHookResult = ReturnType<typeof useLessonForLmsQuery>;
export type LessonForLmsLazyQueryHookResult = ReturnType<typeof useLessonForLmsLazyQuery>;
export type LessonForLmsQueryResult = Apollo.QueryResult<LessonForLmsQuery, LessonForLmsQueryVariables>;
export const GroupLessonAttendanceForLmsDocument = gql`
    query groupLessonAttendanceForLms($groupLessonId: ID) {
  groupLessonAttendanceForLms(groupLessonId: $groupLessonId) {
    id
    attendance
    student {
      id
      user {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGroupLessonAttendanceForLmsQuery__
 *
 * To run a query within a React component, call `useGroupLessonAttendanceForLmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupLessonAttendanceForLmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupLessonAttendanceForLmsQuery({
 *   variables: {
 *      groupLessonId: // value for 'groupLessonId'
 *   },
 * });
 */
export function useGroupLessonAttendanceForLmsQuery(baseOptions?: Apollo.QueryHookOptions<GroupLessonAttendanceForLmsQuery, GroupLessonAttendanceForLmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupLessonAttendanceForLmsQuery, GroupLessonAttendanceForLmsQueryVariables>(GroupLessonAttendanceForLmsDocument, options);
      }
export function useGroupLessonAttendanceForLmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupLessonAttendanceForLmsQuery, GroupLessonAttendanceForLmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupLessonAttendanceForLmsQuery, GroupLessonAttendanceForLmsQueryVariables>(GroupLessonAttendanceForLmsDocument, options);
        }
export type GroupLessonAttendanceForLmsQueryHookResult = ReturnType<typeof useGroupLessonAttendanceForLmsQuery>;
export type GroupLessonAttendanceForLmsLazyQueryHookResult = ReturnType<typeof useGroupLessonAttendanceForLmsLazyQuery>;
export type GroupLessonAttendanceForLmsQueryResult = Apollo.QueryResult<GroupLessonAttendanceForLmsQuery, GroupLessonAttendanceForLmsQueryVariables>;
export const GroupLessonAttachmentForLmsDocument = gql`
    query groupLessonAttachmentForLms($groupLessonId: ID, $attachmentRole: String) {
  groupLessonAttachmentForLms(
    groupLessonId: $groupLessonId
    attachmentRole: $attachmentRole
  ) {
    id
    title
    file
  }
}
    `;

/**
 * __useGroupLessonAttachmentForLmsQuery__
 *
 * To run a query within a React component, call `useGroupLessonAttachmentForLmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupLessonAttachmentForLmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupLessonAttachmentForLmsQuery({
 *   variables: {
 *      groupLessonId: // value for 'groupLessonId'
 *      attachmentRole: // value for 'attachmentRole'
 *   },
 * });
 */
export function useGroupLessonAttachmentForLmsQuery(baseOptions?: Apollo.QueryHookOptions<GroupLessonAttachmentForLmsQuery, GroupLessonAttachmentForLmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupLessonAttachmentForLmsQuery, GroupLessonAttachmentForLmsQueryVariables>(GroupLessonAttachmentForLmsDocument, options);
      }
export function useGroupLessonAttachmentForLmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupLessonAttachmentForLmsQuery, GroupLessonAttachmentForLmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupLessonAttachmentForLmsQuery, GroupLessonAttachmentForLmsQueryVariables>(GroupLessonAttachmentForLmsDocument, options);
        }
export type GroupLessonAttachmentForLmsQueryHookResult = ReturnType<typeof useGroupLessonAttachmentForLmsQuery>;
export type GroupLessonAttachmentForLmsLazyQueryHookResult = ReturnType<typeof useGroupLessonAttachmentForLmsLazyQuery>;
export type GroupLessonAttachmentForLmsQueryResult = Apollo.QueryResult<GroupLessonAttachmentForLmsQuery, GroupLessonAttachmentForLmsQueryVariables>;
export const GroupLessonAttachmentDocument = gql`
    query groupLessonAttachment($attachmentId: ID, $attachmentRole: String) {
  groupLessonAttachment(
    attachmentId: $attachmentId
    attachmentRole: $attachmentRole
  ) {
    id
    title
    file
  }
}
    `;

/**
 * __useGroupLessonAttachmentQuery__
 *
 * To run a query within a React component, call `useGroupLessonAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupLessonAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupLessonAttachmentQuery({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *      attachmentRole: // value for 'attachmentRole'
 *   },
 * });
 */
export function useGroupLessonAttachmentQuery(baseOptions?: Apollo.QueryHookOptions<GroupLessonAttachmentQuery, GroupLessonAttachmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupLessonAttachmentQuery, GroupLessonAttachmentQueryVariables>(GroupLessonAttachmentDocument, options);
      }
export function useGroupLessonAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupLessonAttachmentQuery, GroupLessonAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupLessonAttachmentQuery, GroupLessonAttachmentQueryVariables>(GroupLessonAttachmentDocument, options);
        }
export type GroupLessonAttachmentQueryHookResult = ReturnType<typeof useGroupLessonAttachmentQuery>;
export type GroupLessonAttachmentLazyQueryHookResult = ReturnType<typeof useGroupLessonAttachmentLazyQuery>;
export type GroupLessonAttachmentQueryResult = Apollo.QueryResult<GroupLessonAttachmentQuery, GroupLessonAttachmentQueryVariables>;
export const StudentsMarksDocument = gql`
    query studentsMarks($lessonsId: ID!, $lessonIdForMarks: String!, $taskType: String!) {
  studentsMarks(lessonsId: $lessonsId) {
    edges {
      node {
        id
        user {
          id
          firstName
          lastName
          student {
            id
          }
        }
        marks(taskType: $taskType, lessonId: $lessonIdForMarks) {
          edges {
            node {
              id
              date
              mark
              hwMark
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStudentsMarksQuery__
 *
 * To run a query within a React component, call `useStudentsMarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsMarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsMarksQuery({
 *   variables: {
 *      lessonsId: // value for 'lessonsId'
 *      lessonIdForMarks: // value for 'lessonIdForMarks'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useStudentsMarksQuery(baseOptions: Apollo.QueryHookOptions<StudentsMarksQuery, StudentsMarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentsMarksQuery, StudentsMarksQueryVariables>(StudentsMarksDocument, options);
      }
export function useStudentsMarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentsMarksQuery, StudentsMarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentsMarksQuery, StudentsMarksQueryVariables>(StudentsMarksDocument, options);
        }
export type StudentsMarksQueryHookResult = ReturnType<typeof useStudentsMarksQuery>;
export type StudentsMarksLazyQueryHookResult = ReturnType<typeof useStudentsMarksLazyQuery>;
export type StudentsMarksQueryResult = Apollo.QueryResult<StudentsMarksQuery, StudentsMarksQueryVariables>;
export const StudentMarksDocument = gql`
    query studentMarks($lessonsId: ID!) {
  studentMarks(lessonsId: $lessonsId) {
    edges {
      node {
        mark
        hwMark
        date
        taskType
      }
    }
  }
}
    `;

/**
 * __useStudentMarksQuery__
 *
 * To run a query within a React component, call `useStudentMarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentMarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentMarksQuery({
 *   variables: {
 *      lessonsId: // value for 'lessonsId'
 *   },
 * });
 */
export function useStudentMarksQuery(baseOptions: Apollo.QueryHookOptions<StudentMarksQuery, StudentMarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentMarksQuery, StudentMarksQueryVariables>(StudentMarksDocument, options);
      }
export function useStudentMarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentMarksQuery, StudentMarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentMarksQuery, StudentMarksQueryVariables>(StudentMarksDocument, options);
        }
export type StudentMarksQueryHookResult = ReturnType<typeof useStudentMarksQuery>;
export type StudentMarksLazyQueryHookResult = ReturnType<typeof useStudentMarksLazyQuery>;
export type StudentMarksQueryResult = Apollo.QueryResult<StudentMarksQuery, StudentMarksQueryVariables>;
export const StudentResumeDocument = gql`
    query StudentResume($language: String) {
  studentResume(language: $language) {
    id
    firstName
    lastName
    email
    phone
    jobTitle
    additionalInfo
    image
    language
    tools {
      toolsName
    }
    skills {
      skillName
    }
    educations {
      id
      edInstitution
      degree
      specialization
      startDateYear
      startDateMonth
      endDateYear
      endDateMonth
    }
    cvMedias {
      id
      media {
        mediaName
      }
      socialLink
    }
    cvExperience {
      id
      company
      jobTitle
      startDateYear
      startDateMonth
      endDateYear
      endDateMonth
      projects {
        id
        projectName
        description
        summary
        technologies
      }
    }
    cvLanguages {
      id
      languageLevel
      language {
        language
      }
    }
  }
}
    `;

/**
 * __useStudentResumeQuery__
 *
 * To run a query within a React component, call `useStudentResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentResumeQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useStudentResumeQuery(baseOptions?: Apollo.QueryHookOptions<StudentResumeQuery, StudentResumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentResumeQuery, StudentResumeQueryVariables>(StudentResumeDocument, options);
      }
export function useStudentResumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentResumeQuery, StudentResumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentResumeQuery, StudentResumeQueryVariables>(StudentResumeDocument, options);
        }
export type StudentResumeQueryHookResult = ReturnType<typeof useStudentResumeQuery>;
export type StudentResumeLazyQueryHookResult = ReturnType<typeof useStudentResumeLazyQuery>;
export type StudentResumeQueryResult = Apollo.QueryResult<StudentResumeQuery, StudentResumeQueryVariables>;
export const ResumeSocialMediaDocument = gql`
    query ResumeSocialMedia {
  allCvSocialMedias {
    edges {
      node {
        id
        mediaName
      }
    }
  }
}
    `;

/**
 * __useResumeSocialMediaQuery__
 *
 * To run a query within a React component, call `useResumeSocialMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useResumeSocialMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResumeSocialMediaQuery({
 *   variables: {
 *   },
 * });
 */
export function useResumeSocialMediaQuery(baseOptions?: Apollo.QueryHookOptions<ResumeSocialMediaQuery, ResumeSocialMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResumeSocialMediaQuery, ResumeSocialMediaQueryVariables>(ResumeSocialMediaDocument, options);
      }
export function useResumeSocialMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResumeSocialMediaQuery, ResumeSocialMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResumeSocialMediaQuery, ResumeSocialMediaQueryVariables>(ResumeSocialMediaDocument, options);
        }
export type ResumeSocialMediaQueryHookResult = ReturnType<typeof useResumeSocialMediaQuery>;
export type ResumeSocialMediaLazyQueryHookResult = ReturnType<typeof useResumeSocialMediaLazyQuery>;
export type ResumeSocialMediaQueryResult = Apollo.QueryResult<ResumeSocialMediaQuery, ResumeSocialMediaQueryVariables>;