import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { NetworkErrors } from 'context';

export const useGoToErrorPage = () => {
  const navigate = useNavigate();

  const goToErrorPage = useCallback(() => {
    navigate(`/${NetworkErrors.otherError}`, { replace: true });
  }, [navigate]);

  return goToErrorPage;
};
